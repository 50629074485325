// INTEREST HEADER
.interest-header {
    padding-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 50px;
    background-color: $white;
    z-index: 10;
    button {
        font-size: 18px;
        line-height: 26px;
    }
    p.lg {
        color: $neutral-color-n-40;
    }
}
.text-area-label {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .word-counter {
        padding-top: 0px;
        padding-bottom: 6px;
    }
}
.text-area {
    textarea {
        overflow: hidden;
    }
}
.categories-name {
    color: $neutral-color-n-10;
    padding-bottom: 16px;
}
.wrapper-categories {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}
.cat-item {
    position: relative;
    img {
        &.interest-card-img {
            max-width: 30px !important;
        }
    }
    input {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        cursor: pointer;
        opacity: 0;
    }
    .item {
        display: flex;
        align-items: center;
        column-gap: 8px;
        padding: 5px 12px;
        max-height: 36px;
        border-radius: 100px;
        border: 1px solid $neutral-color-n-70;
        span {
            color: $neutral-color-n-20;
            display: inline-block;
        }
    }
    input[type='checkbox']:checked + .item {
        background-color: $primary-color-p-95;
        border-color: $primary-color-p-50-main;
        span {
            color: $neutral-color-n-10;
        }
    }
}
.categories-container {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
}
.not-selectable {
    .categories-name {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: $neutral-color-n-40;
        padding-bottom: 8px;
    }
    .item {
        span {
            font-weight: 500;
            color: $neutral-color-n-10;
        }
        background-color: #e5f2ff;
        border-color: #0080ff;
    }
}

// BREADCRUMB ACTION
.bread-crumb {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 24px;
    flex-wrap: wrap;
    row-gap: 16px;
}
.breadcrumb {
    align-items: baseline;
    margin: 0px;
}
.breadcrumb-item + .breadcrumb-item::before {
    content: url('../../../../public/images/icons/BREADCRUMB-arrow.svg');
    margin-top: 2px;
}
.breadcrumb-item + .breadcrumb-item::before {
    padding-right: 4px;
}
.breadcrumb-item + .breadcrumb-item {
    padding-left: 4px;
}
.breadcrumb-item {
    font-size: 23px;
    font-weight: 500;
    line-height: 32px;
    color: $primary-color-p-50-main;
    &.active {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: $neutral-color-n-10;
    }
}

// TIME SLOTS
.slots-container {
    display: flex;
    position: relative;
    z-index: 0;
    flex-wrap: wrap;
    gap: 8px;
    li {
        position: relative;
        input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            opacity: 0;
            cursor: pointer;
        }
        input[type='checkbox']:checked + .time-slot-box {
            background-color: $primary-color-p-50-main;
            border-color: $primary-color-p-50-main;
            p {
                color: $white;
            }
            img {
                filter: brightness(10);
            }
        }
        input[type='radio']:checked + .time-slot-box {
            background-color: $primary-color-p-50-main;
            border-color: $primary-color-p-50-main;
            p {
                color: $white;
            }
            img {
                filter: brightness(10);
            }
        }
    }
}
.time-slot-box {
    padding: 11px 6px;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    gap: 8px;
    border: 1.5px solid $primary-color-p-70;
    width: 160px;
    border-radius: 16px;
}

// ROW ACTION BTN
.action-wrapper {
    display: flex;
    align-items: center;
    max-width: 420px;
    justify-content: space-between;
    column-gap: 16px;
}
.row-action {
    display: flex;
    gap: 8px;
    .row-action-btn {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        border: 1px solid;
        border-radius: 25px;
        padding: 3px 11px;
        display: flex;
        align-items: center;
        gap: 4px;
        &.disable-action {
            opacity: 0.5;
            cursor: not-allowed;
        }
        &.edit {
            background: rgba(0, 128, 255, 0.1);
            border-color: $primary-color-p-50-main;
            color: $primary-color-p-50-main;
        }
        &.delete {
            background: rgba(237, 70, 30, 0.1);
            border-color: $state-color-error;
            color: $state-color-error;
        }
        &.chat {
            background: rgba(0, 128, 255, 0.1);
            border-color: $primary-color-p-50-main;
            color: $primary-color-p-50-main;
            &.accept {
                background-color: $primary-color-p-50-main;
                color: $white;
            }
        }
        &.call {
            background: $primary-color-p-50-main;
            border-color: $primary-color-p-50-main;
            color: $white;
        }
        &.reschedule {
            background: rgba(45, 180, 82, 0.1);
            border-color: $state-color-success;
            color: $state-color-success;
        }
        &.cancel {
            background: rgba(237, 70, 30, 0.1);
            border-color: $state-color-error;
            color: $state-color-error;
        }
        &.rescheduleDisable {
            border-color: $neutral-color-n-70;
            color: $neutral-color-n-70;
        }
    }
    .time-left {
        border: 1px solid $neutral-color-n-10;
        color: $neutral-color-n-10;
        border-radius: 16px;
        padding: 3px 11px;
        white-space: nowrap;
    }
}

// LOADER
.loader {
    width: 100%;
    height: 100vh;
    height: 100svh;
    background-color: #036;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &.inner-loader {
        background-color: transparent;
        position: absolute;
        height: 100%;
    }
}
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.animate-logo {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    max-width: 230px;
    .rotate {
        width: 100%;
    }
    .logo {
        transition: all 0.3s ease;
        max-height: 50%;
        position: absolute;
        top: 50%;
        width: auto;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
    }

    .rotate {
        animation: rotate 2s linear infinite;
    }
}

// TITLE HEADER
.title-header {
    padding-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    flex-wrap: wrap;
    .back-arrow-btn {
        display: flex;
        align-items: center;
        gap: 8px;
        .black-arrow {
            filter: brightness(0);
        }
    }
    .action {
        display: flex;
        column-gap: 12px;
        flex-wrap: wrap;
        row-gap: 10px;
    }
}

// TOASTER
.toaster-wrapper {
    .Toastify__toast {
        padding: 12px 16px;
        border-radius: 16px;

        &.error-bg {
            background-color: $state-color-error;
            .Toastify__toast-body > div:last-child {
                font-size: 20px;
                line-height: 26px;
            }
        }
        &.info-bg {
            background-color: $primary-color-p-50-main;
        }
        &.warn-bg {
            background-color: $state-color-warning;
        }
        &.success-bg {
            background-color: $state-color-success;
        }
    }
    .Toastify__toast-icon {
        width: auto;
        margin-inline-end: 8px;
    }
    .Toastify__toast-body {
        padding: 0px;
    }
    .Toastify__toast-body > div:last-child {
        color: $white;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }
}
.cookies-warn-wrapper {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 999;
    background-color: #fafafa;
    padding: 10px;
    border: 1px solid rgb(224, 224, 224);
    border-radius: 12px;
    width: calc(100% - 20px);
    max-width: 700px;
    animation: bottomToTop 0.5s ease;
    p {
        font-size: 16px;
    }
}
.warn-header {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    justify-content: space-between;
    button {
        border-radius: 6px;
    }
    h5 {
        margin: 0px;
    }
}
@keyframes bottomToTop {
    from {
        bottom: -100%;
    }
    to {
        bottom: 10px;
    }
}
.public-naves {
    display: flex;
    align-items: center;
    margin-top: 40px;
    gap: 16px;
    flex-wrap: wrap;
    li a {
        color: $white;
    }
}
@media (max-width: 1399px) {
    .animate-logo {
        max-width: 190px;
        .rotate {
            width: 100%;
        }
    }
}
@media (max-width: 992px) {
    .animate-logo {
        max-width: 160px;
        .rotate {
            width: 100%;
        }
    }
}

@media (max-width: 767px) {
    .animate-logo {
        max-width: 160px;
        .rotate {
            width: 100%;
        }
    }
    .categories-name {
        padding-bottom: 8px;
    }
}

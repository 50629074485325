.react-bootstrap-table {
    overflow: auto;
    background-color: $white;
    min-height: 400px;
    border-radius: 20px 20px 0 0;
    .table {
        margin: 0px;
        tbody {
            border-top: 1px solid $neutral-color-n-90;
            tr {
                .css-1nmdiq5-menu {
                    padding: 0px;
                    .css-1n6sfyn-MenuList,
                    .css-qr46ko {
                        max-height: 160px;
                    }
                }
            }
            tr:last-child {
                .css-1nmdiq5-menu {
                    top: unset;
                    bottom: 100%;
                }
            }
            tr:first-of-type {
                .css-1nmdiq5-menu {
                    top: 100%;
                    bottom: unset;
                }
            }
            tr:nth-last-child(2) {
                .css-1nmdiq5-menu {
                    top: unset;
                    bottom: 100%;
                }
            }
            tr:first-child:nth-last-child(2) {
                .css-1nmdiq5-menu {
                    top: 100%;
                    bottom: unset;
                }
            }
        }
    }
}
.time-header {
    max-width: 800px;
    min-width: 380px;
    width: 580px;
}
.date-header {
    min-width: 180px;
}
.action-header {
    min-width: 260px;
}
.table-bordered > :not(caption) > * > * {
    border: 0px;
}
.table-bordered > :not(caption) > * {
    border: 0px;
}
.table > thead {
    tr {
        border: 0px;
        th {
            min-width: 190px;
            padding: 24px 12px;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            &:first-child {
                padding-left: 24px;
            }
            &:last-child {
                padding-right: 24px;
            }
        }
    }
}
.table {
    tbody {
        tr {
            border: 0px;
            td {
                padding: 24px 12px !important;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                vertical-align: middle;
                border-bottom: 1px solid $neutral-color-n-90 !important;

                &:first-child {
                    padding-left: 24px !important;
                }
                &:last-child {
                    padding-right: 24px !important;
                }
            }
        }
    }
}
.react-bootstrap-table-pagination {
    padding: 16px 24px;
    background-color: $white;
    border-radius: 0 0 20px 20px;
    display: flex;
    align-items: center;
    margin: 0px;
    & > div {
        padding: 0px;
    }
    .react-bootstrap-table-pagination-list {
        display: flex;
        justify-content: flex-end;
    }
}
.page-link {
    border: 0px;
}
.page-item {
    a {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
        padding: 0px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        border-radius: 8px;
        justify-content: center;
        color: $neutral-color-n-60;
        &:focus {
            box-shadow: none;
        }
    }
    &.disabled {
        a {
            background-color: transparent;
            cursor: not-allowed !important;
            img {
                opacity: 0.5;
            }
        }
    }
}
.active > .page-link,
.page-link.active {
    background-color: $primary-color-p-50-main;
}
.react-bootstrap-table-pagination-total {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $neutral-color-n-60;
}
@media (max-width: 1199px) {
    .table > thead tr th {
        padding-block: 20px;
    }
    .table > thead tr th:first-child {
        padding-left: 20px;
    }
    .table > thead tr th:last-child {
        padding-left: 20px;
    }
    .table tbody tr td {
        padding-block: 20px !important;
    }
    .table tbody tr td:first-child {
        padding-left: 20px !important;
    }
    .table tbody tr td:last-child {
        padding-left: 20px !important;
    }
}
@media (max-width: 992px) {
    .table > thead tr th {
        padding-block: 16px;
    }
    .table > thead tr th:first-child {
        padding-left: 16px;
    }
    .table > thead tr th:last-child {
        padding-left: 16px;
    }
    .table tbody tr td {
        padding-block: 16px !important;
    }
    .table tbody tr td:first-child {
        padding-left: 16px !important;
    }
    .table tbody tr td:last-child {
        padding-left: 16px !important;
    }
}
.table-search {
    position: absolute;
    right: 0;
    top: 0;
    .sr-only {
        display: none;
    }
    img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 16px;
    }
    .custom-searchbar {
        padding: 6px 16px;
        padding-left: 36px;
        background-color: transparent;
        position: relative;
        z-index: 1;
        border-radius: 100px;
        border: 1px solid $neutral-color-n-80;
        &:focus {
            box-shadow: none;
            outline: none;
        }
    }
}
.filter-column {
    display: flex;
    align-items: center;
    column-gap: 8px;
    &.profile-detail-col {
        min-width: 300px;
    }
}
@media (max-width: 767px) {
    .table-search {
        position: relative;
        margin-bottom: 20px;
        .custom-searchbar {
            padding: 6px 16px !important;
            padding-left: 36px !important;
            background-color: transparent;
        }
    }
}
@media (max-width: 575px) {
    .react-bootstrap-table-pagination {
        justify-content: center;
        text-align: center;
        row-gap: 20px;
    }
    .react-bootstrap-table-pagination .react-bootstrap-table-pagination-list {
        justify-content: center;
    }
}

.cms-page {
    display: flex;
    height: 100%;
    flex-direction: column;
    .content-wrapper {
        flex: 1;
        p {
            color: $neutral-color-n-30;
            &:not(:last-child) {
                padding-bottom: 20px;
            }
        }
        ul {
            list-style: unset;
            padding-left: 30px;
            margin-bottom: 16px;
            li {
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
                &:not(:last-child) {
                    padding-bottom: 16px;
                }
            }
            &.alphabet-list {
                list-style: lower-alpha;
                li:not(:last-child) {
                    padding-bottom: 0px;
                }
            }
            &.dots-list {
                list-style: disc;
            }
        }
        table {
            width: 100%;
            border-collapse: collapse;
            th,
            td {
                border: 1px solid $neutral-color-n-80;
                padding: 8px;
                text-align: left;
            }
            th {
                background-color: $primary-color-p-50-main;
                color: $white;
            }
        }
    }
    &.contact-us {
        .form-space {
            padding-top: 24px;
            .primary-btn {
                margin: 0 auto;
                width: 100%;
                max-width: 364px;
            }
        }
        .contact-details {
            padding-top: 32px;
            display: flex;
            flex-direction: column;
        }
        .social-media {
            display: flex;
            align-items: center;
            column-gap: 24px;
            padding-top: 16px;

            a {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                border: 1px solid $global-color-black;
                transition: all 0.2s ease-in-out;
                width: 40px;
                height: 40px;
                background-color: $global-color-black;
                img {
                    transition: all 0.2s ease-in-out;
                }
                &:hover {
                    background-color: transparent;
                    img {
                        filter: brightness(0);
                    }
                }
            }
        }
        .contact-info {
            display: flex;
            flex-direction: column;
            padding-top: 24px;
            row-gap: 16px;
            a {
                display: inline-flex;
                align-items: center;
                column-gap: 8px;
                font-size: 20px;
                transition: all 0.2s ease-in-out;

                line-height: 30px;
                color: $neutral-color-n-10;
                img {
                    transition: all 0.1s ease-in-out;
                }
                &:hover {
                    img {
                        filter: invert(36%) sepia(93%) saturate(2637%)
                            hue-rotate(194deg) brightness(98%) contrast(112%);
                    }
                    span {
                        color: $primary-color-p-50-main;
                    }
                }
            }
        }
    }
    &.about-us {
        .blue-text {
            color: $primary-color-p-50-main;
        }
        text-align: justify;
        .custom-tabs .tabs-wrapper {
            justify-content: end;
        }
        .tabs-heading {
            position: absolute;
            top: 6px;
            left: 0;
        }
        .content-wrapper {
            height: 100%;
        }
        .volunteers-logo {
            display: flex;
            gap: 16px;
            flex-wrap: wrap;
            & > div {
                max-width: 200px;
                width: 100%;
                aspect-ratio: 1/0.6;
                border: 1px solid $neutral-color-n-80;
                border-radius: 12px;
                padding: 12px;
                img {
                    width: 100%;
                    object-fit: contain;
                    height: 100%;
                }
            }
        }
        .tab-logo {
            max-width: 300px;
            aspect-ratio: 1/0.6;
            border: 1px solid $neutral-color-n-80;
            border-radius: 12px;
            margin-bottom: 20px;
            padding: 12px;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
    &.public-cms {
        background-color: #f5f5f5;
    }
    .public-header {
        background-color: $primary-color-p-20;
        padding-block: 12px;
        margin-bottom: 20px;
        position: sticky;
        top: 0;
        z-index: 99;
    }
}
.audio-player {
    width: 100%;
    display: flex;
    align-items: center;
    max-width: 416px;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid $neutral-color-n-80;
    gap: 8px;
    .audio-wrapper {
        flex: 1;
        .time-duration {
            display: flex;
            padding-top: 3px;
            align-items: center;
            justify-content: space-between;
        }
    }
    .time-line {
        height: 5px;
        background-color: $neutral-color-n-80;
        cursor: pointer;
        border-radius: 10px;
        overflow: hidden;
        & > div {
            background-color: $primary-color-p-50-main;
        }
    }
    .time-text {
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;
        color: $primary-color-p-50-main;
    }
}
.player-with-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    padding-bottom: 20px;
}
.global-about-us {
    .about-us {
        padding-top: 48px;
    }
}
.header-about {
    background-color: $primary-color-p-20;
    padding-block: 12px;
}
.video-player {
    max-width: 800px;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    display: flex;
    border: 1px solid #ccc;
    margin: 0 auto;
    margin-bottom: 20px;
    .overlay {
        width: 100%;
        position: absolute;
        height: 100%;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.2) 0%,
            rgba(0, 0, 0, 0.2) 100%
        );
        .simple-btn {
            position: relative;
            transform: unset;
            top: unset;
            left: unset;
        }
    }
    &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.2) 0%,
            rgba(0, 0, 0, 0.2) 100%
        );
        z-index: 1;
    }
    &.playing {
        &::after {
            content: unset;
        }
    }
    video {
        width: 100%;
        aspect-ratio: 16/9;
        height: 100%;
    }
    .simple-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
    }
}
// ACCORDION CSS
.custom-accordion {
    .accordion-button:not(.collapsed) {
        box-shadow: none;
    }
    .accordion {
        position: relative;
        z-index: 0;
        .accordion-button::after {
            background-image: url('../../../../public/images/accordion-arrow.svg');
        }
        .accordion-item {
            border: 0px;

            &:not(:last-child) {
                margin-bottom: 16px;
            }
        }
        .accordion-header {
            .accordion-button {
                border: 1px solid $neutral-color-n-80;
                border-radius: 16px;
                padding: 12px 16px;
                font-size: 20px;
                line-height: 30px;
                font-style: normal;
                font-weight: 400;
                &:focus {
                    box-shadow: none;
                }
            }
            .accordion-button:not(.collapsed) {
                background-color: transparent;
            }
        }
        .accordion-body {
            border: 1px solid $primary-color-p-50-main;
            border-radius: 16px;
            padding: 12px 16px;
            margin-top: 8px;
        }
    }
}

@media (max-width: 992px) {
    .custom-accordion .accordion .accordion-header .accordion-button {
        font-size: 18px;
        line-height: 24px;
    }
    .cms-page.about-us .tab-logo {
        max-width: 220px;
    }
}
@media (max-width: 767px) {
    .cms-page.contact-us .contact-info a {
        font-size: 18px;
        line-height: 20px;
        img {
            max-width: 22px;
        }
    }
    .custom-accordion .accordion .accordion-header .accordion-button {
        font-size: 17px;
        line-height: 22px;
        border-radius: 12px;
        padding: 10px 14px;
    }
}
@media (max-width: 520px) {
    .cms-page.about-us .tab-logo {
        max-width: 180px;
    }
    .cms-page.about-us .volunteers-logo > div {
        max-width: 160px;
    }
    .cms-page.about-us .tabs-heading {
        position: relative;
        padding-bottom: 20px;
    }
    .cms-page.about-us .custom-tabs .tabs-wrapper {
        justify-content: start;
    }
}

.user-intro-wrap {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    .notify-btn {
        background-color: white;
        position: relative;
        .span {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: $state-color-error;
            font-size: 8px;
            display: inline-block;
            position: absolute;
            top: 10px;
            right: 10px;
            color: $white;
        }
    }
    .dash-header {
        position: inherit;
        z-index: auto;
    }
    .css-13cymwt-control {
        display: flex;
        align-items: center;
        column-gap: 5px;
    }
    .custom-option {
        display: flex;
        align-items: center;
        column-gap: 5px;
    }
    .z-index {
        z-index: 5;
        pointer-events: none;
    }
    .profile-intro {
        position: absolute;
        z-index: 5;
        right: 0;
        top: 30px;
        padding-top: 110px;
        &.notification {
            @media (max-width: 767px) {
                right: -25px;
            }
        }
        p {
            width: 240px;
            color: $white;
            font-size: 20px;
            text-align: center;
        }
        &::after {
            pointer-events: none;
            top: 0;
            content: '';
            right: 65px;
            position: absolute;
            transform: rotate(77deg) scaleX(-1);
            width: 100px;
            height: 100px;
            background: url('../../../../public/images/home-tut-arrow.svg')
                no-repeat center / contain;
        }
    }
}

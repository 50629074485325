.rewards {
    display: flex;
    flex-direction: column;
    height: 100%;
    .content-wrapper {
        flex: 1;
    }
}
.rewards-wrapper {
    display: flex;
    gap: 24px;
    height: 100%;
    justify-content: space-between;
    .reward-col {
        background-color: $neutral-color-n-96;
        padding: 40px;
        border-radius: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:first-child {
            width: 60%;
        }
        &:last-child {
            width: 40%;
        }
    }
}
.no-target {
    max-width: 364px;
    margin: 0 auto;
    flex-direction: column;
    width: 100%;
    display: flex;
    gap: 48px;
    text-align: center;
    img {
        max-width: 192px;
        margin: 0 auto;
    }
}
.right-cards {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 40px;
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
    .card-hours {
        border-radius: 16px;
        border: 1px solid $neutral-color-n-80;
        background-color: $white;
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        justify-content: center;
        img {
            max-width: 48px;
        }
        &.blue {
            border-color: $primary-color-p-50-main;
            background-color: $primary-color-p-50-main;
            color: $white;
            img {
                filter: brightness(10);
            }
        }
    }
}
.hours-progress {
    max-width: 240px;
    margin: 0 auto;
    p {
        color: $neutral-color-n-40;
    }
}
.congratulations {
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    align-items: center;
    .lg {
        max-width: 494px;
    }
    width: 100%;
    text-align: center;
    margin: 0 auto;
    .action {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        flex-wrap: wrap;
        gap: 12px;
        button,
        a {
            min-width: 364px;
        }
    }
}
.manage-target {
    height: 100%;
    display: flex;
    flex-direction: column;
    .content-wrapper {
        flex: 1;
    }
    .tab-content {
        background-color: $neutral-color-n-96;
        border-radius: 24px;
        padding: 24px;
        .row {
            row-gap: 24px;
        }
    }
    .abs-btn {
        position: absolute;
        top: 0;
        right: 0;
    }
    .tabs-wrapper {
        padding-bottom: 30px;
    }
}
.hours-card {
    position: relative;
    height: 100%;
    input[type='radio'] {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        cursor: pointer;
        z-index: 2;
    }
    .box {
        padding: 24px;
        border: 1px solid $neutral-color-n-80;
        background-color: $white;
        border-radius: 16px;
        height: 100%;
        .circle {
            border-radius: 50%;
            position: relative;
            width: 32px;
            height: 32px;
            border: 1px solid $neutral-color-n-50;
            background-color: transparent;
        }
        p {
            padding-top: 8px;
            color: $neutral-color-n-30;
        }
        &.completed {
            border-color: $primary-color-p-50-main;
        }
    }
    input[type='radio']:checked ~ .box {
        border-color: $primary-color-p-50-main;
        .circle {
            &::after {
                width: calc(100% - 7px);
                height: calc(100% - 7px);
                border-radius: 50%;
                background-color: $primary-color-p-50-main;
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    .status {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
        .sm {
            padding: 0px;
        }
    }
    .hr-card-icon {
        border-radius: 12px;
        border: 2px solid $neutral-color-n-80;
        width: 48px;
        height: 48px;
        background-color: $neutral-color-n-95;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        img {
            width: 100%;
            object-fit: cover;
            height: 100%;
        }
    }
}
.accom-popup-field {
    display: flex;
    flex-direction: column;
    justify-self: start;
    text-align: left;
    padding-top: 16px;
    row-gap: 16px;
}
@keyframes float {
    0% {
        transform: translateY(100%);
    }
    95% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(-100%);
        display: none;
    }
}
.balloons-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    pointer-events: none;
    animation: float 8s ease forwards;
    &.animate {
        animation-play-state: running;
    }
    img {
        position: absolute;
    }
    .balloon1 {
        top: 20%;
        left: 10%;
    }
    .balloon2 {
        top: 50%;
        left: 20%;
    }
    .balloon3 {
        top: 0%;
        left: 40%;
    }
    .balloon4 {
        top: 50%;
        left: 50%;
    }
    .balloon5 {
        top: 10%;
        left: unset;
        right: 20%;
    }
    .balloon6 {
        top: unset;
        bottom: 10%;
        left: unset;
        right: 10%;
    }
}
.accomplished {
    height: 100%;
    display: flex;
    flex-direction: column;
    .content-wrapper {
        flex: 1;
    }
    .content-accom {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: center;
        text-align: center;
        p.lg {
            color: $neutral-color-n-30;
        }
    }
    .accom-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;

        flex-wrap: wrap;
        img {
            width: 170px;
            object-fit: contain;
        }
    }
    .accom-card {
        padding: 12px 16px;
        border-radius: 16px;
        border: 1px solid $neutral-color-n-80;
        display: flex;
        column-gap: 30px;
        align-items: start;
        margin-top: 8px;
        text-align: left;
    }
    .action-accom {
        display: flex;
        justify-content: center;
    }
}
@media (max-width: 1380px) {
    .no-target {
        gap: 20px;
    }
    .hours-progress {
        max-width: 220px;
        h1 {
            font-size: 24px;
        }
    }
}
@media (min-width: 993px) and (max-width: 1199px) {
    .accomp-logo {
        max-width: 300px;
    }
    .rewards-wrapper .reward-col:first-child {
        width: 55%;
    }
    .rewards-wrapper .reward-col:last-child {
        width: 45%;
    }
    .rewards-wrapper .reward-col {
        padding: 20px;
    }
}

@media (max-width: 1099px) {
    .accom-wrapper {
        padding-top: 20px;
    }
    .accomp-logo {
        max-width: 260px;
    }
    .hours-card .box {
        padding: 16px;
    }
    .hours-card .box .circle {
        width: 28px;
        height: 28px;
    }
    .congratulations img {
        max-width: 120px;
    }
    .balloons-screen {
        img {
            position: absolute;
            max-height: 220px;
        }
        .balloon1 {
            top: 30%;
            left: 30%;
        }
        .balloon2 {
            top: 50%;
            left: 10%;
        }
        .balloon3 {
            top: 5%;
            left: 40%;
        }
        .balloon4 {
            top: 50%;
            left: 50%;
        }
        .balloon5 {
            top: 10%;
            left: unset;
            right: 20%;
        }
        .balloon6 {
            top: unset;
            bottom: 10%;
            left: unset;
            right: 10%;
        }
    }
}
@media (max-width: 767px) {
    .balloons-screen {
        img {
            position: absolute;
            max-height: 180px;
        }
        .balloon1 {
            top: 10%;
            left: 10%;
        }
        .balloon2 {
            top: 50%;
            left: 20%;
        }
        .balloon3 {
            top: 30%;
            left: 30%;
        }
        .balloon4 {
            top: 50%;
            left: 50%;
        }
        .balloon5 {
            top: 10%;
            left: unset;
            right: 20%;
        }
        .balloon6 {
            top: unset;
            bottom: 10%;
            left: unset;
            right: 20%;
        }
    }
    .accomp-logo {
        max-width: 200px;
    }
    .congratulations img {
        max-width: 100px;
    }
    .rewards-wrapper {
        flex-direction: column;
        flex-wrap: wrap;
    }
    .rewards-wrapper .reward-col {
        width: 100% !important;
    }
    .right-cards {
        gap: 24px;
    }
    .rewards-wrapper .reward-col {
        padding: 24px;
        border-radius: 16px;
    }
}
@media (max-width: 575px) {
    .accomp-logo {
        max-width: 180px;
    }
    .congratulations .action button,
    .congratulations .action a {
        min-width: 100%;
    }
    .congratulations img {
        max-width: 90px;
    }
    .manage-target .abs-btn {
        position: relative;
        margin-bottom: 20px;
    }
}

.appointments {
    height: 100%;
    display: flex;
    flex-direction: column;
    .tableDropdown {
        max-width: 160px;
        &.custom-dropdown .css-13cymwt-control,
        &.custom-dropdown .css-16xfy0z-control,
        &.custom-dropdown .css-t3ipsp-control {
            border-radius: 8px;
            border-color: $neutral-color-n-80;
            padding-block: 7px;
        }
        &.custom-dropdown .css-1nmdiq5-menu {
            border-radius: 8px;
            border: 0px;
            box-shadow: -4px -4px 8px 0px rgba(0, 0, 0, 0.06),
                4px 4px 8px 0px rgba(0, 0, 0, 0.06);
        }
        .css-1wlit7h-NoOptionsMessage,
        .css-9x5mqu {
            padding: 0px;
        }
    }
}
.tab-counter {
    display: flex;
    align-items: center;
    column-gap: 8px;
}
.white-date-label {
    background-color: $white;
    margin-bottom: 30px;
    border-radius: 16px;
    padding: 12px 16px;
}
.custom-tabs {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    .tab-content {
        height: 100%;
        .tab-pane {
            height: 100%;
        }
    }
    .tabs-wrapper {
        padding-bottom: 24px;
        border: 0px;
        gap: 16px;
        li {
            .nav-link {
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                color: $neutral-color-n-40;
                padding: 6px 16px;
                border-radius: 18px;
                border: 1px solid $neutral-color-n-80;
                &.active {
                    background-color: $primary-color-p-50-main;
                    border-color: $primary-color-p-50-main;
                    color: $white;
                }
            }
        }
    }
}
.time-date {
    display: flex;
    column-gap: 48px;
    align-items: center;
    & > div {
        display: flex;
        align-items: center;
        column-gap: 8px;
        .small {
            color: $neutral-color-n-40;
        }
    }
}
.chat-msg {
    padding-top: 32px;
    display: flex;
    column-gap: 8px;
}
@keyframes NoDATA {
    0% {
        transform: rotate(-10deg);
    }

    50% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(10deg);
    }
}
@-webkit-keyframes NoDATA {
    0% {
        transform: rotate(-10deg);
    }

    50% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(10deg);
    }
}
.no-appointments {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    border-radius: 24px;
    .no-data {
        text-align: center;
        img {
            transform-origin: center;
            transform: rotate(0deg);
            width: 100%;
            padding-bottom: 24px;
            animation: NoDATA 1.3s infinite linear alternate;
            -webkit-animation: NoDATA 1.3s infinite linear alternate;
            max-width: 300px;
            margin: 0 auto;
        }
        p {
            color: $neutral-color-n-20;
        }
    }
}
@media (max-width: 1399px) {
    .no-appointments {
        .no-data {
            img {
                max-width: 260px;
            }
        }
        p.lg {
            font-size: 16px;
        }
    }
    .schedule-call-data .user-dp {
        max-width: 240px;
    }
}
@media (max-width: 1199px) {
    .no-appointments {
        .no-data {
            img {
                max-width: 240px;
            }
        }
    }
}
.dialoguers {
    .details {
        display: flex;
        align-items: center;
        column-gap: 8px;
    }
    p {
        color: $neutral-color-n-10;
    }
    .dp {
        width: 48px;
        max-width: 48px;
        border-radius: 50%;
        overflow: hidden;
        aspect-ratio: 1;
        position: relative;
        img {
            width: 100%;
            object-fit: cover;
            height: 100%;
        }
    }
}
@media (max-width: 992px) {
    .no-appointments {
        .no-data {
            img {
                max-width: 202px;
            }
        }
    }
}
@media (max-width: 767px) {
    .time-date {
        column-gap: 16px;
        img {
            max-width: 30px;
        }
    }
    .chat-msg {
        padding-top: 20px;
        img {
            max-width: 30px;
        }
    }
    .no-appointments {
        .no-data {
            img {
                max-width: 202px;
            }
        }
    }
}

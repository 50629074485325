.home {
    height: 100%;
}
.full-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.call-cards-row {
    row-gap: 24px;
}
.awaiting {
    max-width: 364px;
    margin: 0 auto;
}
.awaiting-screen {
    display: flex;
    align-items: center;
    justify-content: center;
}
.senior-home {
    display: flex;
    flex-direction: column;
}
.call-card {
    border: 6px solid rgba(0, 102, 204, 0.5);
    border-radius: 24px;
    padding: 16px;
    height: 100%;
    .chat_btn {
        max-width: 116px;
        min-width: auto;
    }
    .call_btn {
        max-width: 170px;
        min-width: auto;
    }
    h3 {
        padding-top: 16px;
    }
    .dpWrapper {
        aspect-ratio: 1/0.7;
        border-radius: 24px;
        cursor: pointer;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .location {
        display: flex;
        padding-top: 0px;
        align-items: center;
        gap: 6px;
        color: $primary-color-p-50-main;
    }
    .active-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 24px;
        flex-wrap: wrap;
        gap: 16px;
        button {
            flex: 1;
        }
    }
    .time {
        display: flex;
        align-items: center;
        padding-top: 16px;
        gap: 6px;
        img {
            max-width: 24px;
        }
    }
}
.full-logo-animated {
    display: flex;
    flex-direction: column;
    row-gap: 26px;
    justify-items: center;
    align-items: center;
    .animate-logo {
        max-width: 180px;
    }
    .logo-title {
        max-width: 220px;
    }
}
.set-slots-from-home {
    max-width: 402px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
}
.call-btn-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;
    button {
        img {
            filter: brightness(10);
        }
    }
}

.schedule-btn {
    width: 46px;
    height: 46px;
    background-color: $primary-color-p-50-main;
    border-radius: 12px;
    &.deactivated {
        background-color: #8c8c8c;
        cursor: not-allowed;
    }
}
.schedule-call-data {
    .grey-bg {
        position: relative;
    }
    .user-dp {
        aspect-ratio: 1;
        border-radius: 24px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .interest-heading {
        color: $neutral-color-n-10;
        padding-bottom: 12px;
    }
}
.home-instr {
    background-color: rgba(0, 0, 0, 0.659);
    .swipe-arrow {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .instr-action {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 32px;
        position: absolute;
        bottom: 48px;
        width: 100%;
        button {
            min-width: 122px;
        }
    }
}
.active-home-instr {
    position: relative;
    z-index: 5;
    p {
        max-width: 210px;
        color: white;
        position: absolute;
        bottom: 89px;
        font-size: 20px;
        left: -303px;
    }
    .primary-btn {
        position: relative;
        // min-width: 300px;
        pointer-events: none;
        &::after {
            pointer-events: none;
            top: -13px;
            content: '';
            left: -46%;
            position: absolute;
            transform: translateY(-50%) rotate(73deg);
            width: 89px;
            height: 79px;
            background: url('../../../../public/images/home-tut-arrow.svg')
                no-repeat center / contain;
        }
    }
}
@media (max-width: 1399px) {
    .full-logo-animated {
        .logo-title {
            max-width: 200px;
        }
        .animate-logo {
            max-width: 180px;
        }
    }
}
@media (max-width: 1199px) {
    .set-slots-from-home {
        gap: 20px;
        img {
            max-width: 200px;
        }
    }
}
@media (max-width: 992px) {
    .full-logo-animated {
        gap: 20px;
        .logo-title {
            max-width: 300px;
        }
    }
    .set-slots-from-home {
        gap: 20px;
        img {
            max-width: 180px;
        }
    }
}

.name,
.bio {
    padding-top: 24px;
    .bio-content {
        color: $neutral-color-n-40;
        padding-top: 4px;
    }
}
.location {
    padding-top: 12px;
    li {
        display: flex;
        align-items: start;
        column-gap: 6px;
        span {
            color: var(--primary-color-p-50-main, #0080ff);
            text-align: left;
            font-size: 20px;
            font-weight: 500;
            line-height: 30px;
            color: $primary-color-p-50-main;
        }
    }
}
.matched {
    padding-bottom: 20px;
    .categories-name {
        color: $neutral-color-n-10;
    }
}
.unmatched {
    .categories:not(:last-child) {
        padding-bottom: 20px;
    }
    input {
        display: none;
    }
}
@media (max-width: 767px) {
    .schedule-call-data .grey-bg .schedule-btn {
        img {
            max-width: 40px;
        }
    }
    .call-card {
        border: 4px solid rgba(0, 102, 204, 0.5);
        border-radius: 16px;
        .dpWrapper {
            border-radius: 16px;
        }
    }
    .full-logo-animated {
        .logo-title {
            max-width: 220px;
        }
        .full-logo-animated {
            gap: 20px;
        }
    }
    .set-slots-from-home {
        gap: 16px;
        img {
            max-width: 160px;
        }
    }
    .schedule-call-data {
        .user-dp {
            max-width: 200px;
        }
        .name,
        .bio {
            padding-top: 16px;
        }
        .matched {
            padding-top: 30px;
        }
    }
    .schedule-call-data .interest-heading {
        padding-top: 30px;
    }
}

.video-controls {
    display: flex;
    column-gap: 40px;
    button {
        background-color: transparent;
        padding: 0px;
    }
}
.call-wrapper {
    aspect-ratio: 16/9;
    max-width: 1068px;
    margin: 0 auto;
    position: relative;
    .header {
        h1 {
            color: $white;
        }
        h3 {
            font-weight: 400;
        }
        position: absolute;
        top: 56px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        z-index: 1;
        color: $white;
    }
    .video-controls {
        position: absolute;
        bottom: 24px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
    }
    .str-video__speaker-layout__wrapper {
        width: 100%;
        height: 100%;
        .str-video__speaker-layout {
            width: 100%;
            height: 100%;
            .str-video__speaker-layout__spotlight {
                width: 100%;
                height: 100%;
                .str-video__participant-view {
                    max-width: 100%;
                }
            }
        }
    }
}

@media (max-width: 1199px) {
    .call-wrapper .video-controls {
        column-gap: 24px;
        button {
            img {
                max-width: 40px;
            }
        }
        .end-call {
            img {
                max-width: 50px;
            }
        }
    }
}
.str-video__speaker-layout
    .str-video__speaker-layout__participants-bar-buttons-wrapper {
    position: absolute !important;
    bottom: 16px;
    right: 16px;
}

@media (max-width: 767px) {
    .call-wrapper .video-controls {
        position: relative;
        transform: unset;
        justify-content: center;
        left: unset;
        bottom: unset;
    }
    .call-wrapper {
        aspect-ratio: unset;
    }
    .str-video__speaker-layout
        .str-video__speaker-layout__participants-bar-buttons-wrapper {
        position: relative !important;
        top: unset;
        right: unset;
        bottom: unset;
    }
}

// CUSTOM DROPDOWN
.custom-dropdown {
    &:not(:has(.css-1nmdiq5-menu)) .css-1wy0on6 .arrow {
        transform: rotate(0deg) !important;
    }
    &:has(.css-1nmdiq5-menu) .css-1wy0on6 .arrow {
        transform: rotate(180deg) !important;
    }
    &:not(:has(.css-1nmdiq5-menu)) .css-1hb7zxy-IndicatorsContainer .arrow {
        transform: rotate(0deg) !important;
    }
    &:has(.css-1nmdiq5-menu) .css-1hb7zxy-IndicatorsContainer .arrow {
        transform: rotate(180deg) !important;
    }
    label {
        padding-bottom: 8px;
    }
    .css-b62m3t-container {
        .css-1xc3v61-indicatorContainer .arrow {
            transition: all 0.1s ease-in-out;
        }
        .css-15lsz6c-indicatorContainer .arrow {
            transform: rotate(180deg);
            transition: all 0.1s ease-in-out;
        }
    }
    .css-1u9des2-indicatorSeparator {
        display: none !important;
    }
    .css-qbdosj-Input,
    .css-1fdsijx-ValueContainer,
    .css-1xc3v61-indicatorContainer,
    .css-1dimb5e-singleValue {
        padding: 0px;
        margin: 0px;
    }
    .css-13cymwt-control,
    .css-16xfy0z-control,
    .css-t3ipsp-control {
        padding: 11px 15px;
        min-height: auto;
        border: 1.5px solid $neutral-color-n-50;
        border-radius: 16px;
        cursor: pointer;
    }
    .css-15lsz6c-indicatorContainer {
        padding: 0px !important;
        width: 24px;
        height: 24px;
    }
    .css-1dimb5e-singleValue {
        line-height: 24px;
        font-size: 16px;
        color: $neutral-color-n-10;
    }
    .css-t3ipsp-control {
        box-shadow: none;
    }
    .css-t3ipsp-control:hover {
        border-color: $neutral-color-n-50;
    }
    .css-19bb58m,
    .css-1mkvw8y {
        padding: 0px;
        margin: 0px;
    }
    .css-1nmdiq5-menu {
        box-shadow: none;
        padding: 16px;
        border-radius: 16px;
        background-color: $white;
        border-radius: 16px;
        border: 1.5px solid $neutral-color-n-80;
        .css-d7l1ni-option {
            background-color: transparent;
            color: $neutral-color-n-40;
            border-radius: 8px;
            transition: all 0.1s ease-in-out;
            &:hover {
                background-color: $primary-color-p-50-main;
                color: $white;
            }
        }
        .css-tr4s17-option {
            color: white;
            border-radius: 8px;
        }
        .css-10wo9uf-option {
            color: $neutral-color-n-40;
        }
    }
    .css-hlgwow {
        padding: 0px;
    }
    .css-1n6sfyn-MenuList {
        padding: 0px;
    }
    .css-1jqq78o-placeholder {
        color: $neutral-color-n-40;
        font-size: 16px;
        margin: 0px;
        line-height: 24px;
    }
    .react-datepicker__header {
        padding: 0px;
    }
    .css-1h01tm3-Input {
        padding: 0px;
        margin: 0px;
    }
    .css-894a34-indicatorSeparator {
        display: none;
    }
}
.custom-dropdown.language-selector {
    .css-13cymwt-control,
    .css-t3ipsp-control {
        padding: 11px 15px;
        box-shadow: 0px;
        max-width: 135px;
        margin-left: auto;
    }
    .css-1nmdiq5-menu {
        max-width: 192px;
        right: 0;
        min-width: 170px;
        .css-1n6sfyn-MenuList,
        .css-qr46ko {
            padding: 0px;
            & > div {
                color: $neutral-color-n-40;
                cursor: pointer;
                padding: 8px 16px;
                border-radius: 8px;
                .custom-icon {
                    max-width: 24px;
                    margin-right: 8px;
                }
                &:hover {
                    background-color: $primary-color-p-50-main;
                    color: $white;
                }
            }
        }
    }
}

// CUSTOME DATE PICKER
.date-picker {
    display: flex;
    flex-direction: column;
    label {
        padding-bottom: 8px;
    }
    .date-input {
        position: relative;
        input {
            background-color: transparent;
            &:focus-visible {
                outline: none;
                box-shadow: none;
            }
            padding: 12px 16px;
            width: 100%;
            height: 100%;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            color: $neutral-color-n-10;
            border: 1.5px solid $neutral-color-n-50;
            border-radius: 16px;
        }
        .date-icon {
            display: inline-block;
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .react-datepicker {
        border-radius: 16px;
        overflow: hidden;
        border: 1.5px solid $neutral-color-n-80;
    }
    .react-datepicker__day,
    .react-datepicker__day-name {
        width: 40px;
        height: 40px;
        font-size: 14px;
        line-height: 20px;
        color: $neutral-color-n-30;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        &:hover {
            border-radius: 50%;
        }
    }
    .react-datepicker__day--selected {
        background-color: $primary-color-p-50-main;
        color: $white;
    }
    .cal-header {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 8px;
        select {
            background-color: transparent;
            border: none;
            padding: 8px;
            &:focus-visible {
                outline: none;
            }
            font-size: 16px;
            line-height: 24px;
            color: $neutral-color-n-10;
        }
    }
    .react-datepicker__header {
        background-color: $white;
        .simple-btn {
            &:disabled {
                opacity: 0.3;
            }
        }
    }
    .react-datepicker-popper[data-placement^='top'] {
        padding-bottom: 0px;
    }
    .react-datepicker-popper {
        z-index: 999;
    }
    .cal-dropdown {
        select {
            appearance: none;
            padding-right: 16px;
            position: relative;
            z-index: 2;
            text-align-last: center;
        }
        position: relative;
        &::after {
            content: '';
            position: absolute;
            width: 16px;
            height: 16px;
            background-color: red;
            top: 50%;
            transform: translateY(-50%);
            background: url('../../../../public/images/icons/cal-arrow-drop.svg');
            right: 0;
        }
    }
    .react-datepicker__week {
        .react-datepicker__day--disabled {
            opacity: 0.3;
        }
    }
}

//  CHECKBOX STYLE
.mobile-input {
    text-align: left;
    label {
        padding-bottom: 8px;
    }
}
.mobile-input .react-tel-input .form-control {
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    min-height: 48px;
    border-radius: 16px;
    border-color: #808080;
}
.mobile-input .react-tel-input .flag-dropdown {
    border-radius: 16px 0 0 16px;
    border-color: #808080;
}
.mobile-input .react-tel-input {
    font-family: inherit;
}
.mobile-input .react-tel-input .country-list .search-box {
    margin: 0px;
    width: 100%;
    border-radius: 10px;
    padding: 8px;
}
.mobile-input .react-tel-input .country-list {
    border-radius: 10px;
}
.mobile-input .react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
    border-radius: 16px 0 0 16px;
}
.mobile-input .react-tel-input .flag-dropdown.open .selected-flag,
.mobile-input .react-tel-input .flag-dropdown.open {
    border-radius: 16px 0 0 16px;
    border-color: #808080;
}
.mobile-input .react-tel-input .country-list .search {
    padding: 10px;
}
.form-check.check-box {
    padding-left: 32px;
    margin: 0px;
    text-align: left;
    font-size: 16px;
    line-height: 24px;
    color: $neutral-color-n-40;
    .form-check-input {
        margin-top: 0;
        border-color: $primary-color-p-50-main;
        width: 24px;
        height: 24px;
        border-radius: 4px;
        &[type='radio'] {
            border-radius: 50%;
            width: 32px;
            height: 32px;
            &:checked {
                background-color: transparent;
                background-image: url('../../../../public/images/radio-circle.svg');
                background-size: 20px;
            }
        }
    }
    .form-check-input:checked[type='checkbox'] {
        background-image: url('../../../../public/images/icons/white-checkbox-arrow.svg');
        background-size: 16px;
        background-position: center;
    }
    .form-check-input:checked {
        background-color: $primary-color-p-50-main;
    }
    .form-check-input:focus {
        box-shadow: none;
    }
    .form-check-input {
        margin-left: -32px;
    }
    &.radio-button {
        display: flex;
        align-items: center;
        column-gap: 16px;
        label {
            font-size: 18px;
            font-weight: 500;
            line-height: 26px;
        }
    }
}

// INPUT TYPE CSS
.form-control:focus {
    box-shadow: none;
    border-color: $neutral-color-n-50;
    background: transparent;
    border-color: $primary-color-p-50-main;
}
.form-control {
    padding: 11px 16px;
    border: 1.5px solid $neutral-color-n-50;
    border-radius: 16px;
    color: $neutral-color-n-10;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    background: transparent;
}
.error-input {
    border-color: $state-color-error !important;
}
// MOBILE CODE INPUT
.mobile-input {
    input:focus {
        border-color: #808080;
    }
    .form-control {
        border-left: 0px;
    }
    .dropdown-toggle {
        padding: 12px 0px 12px 16px;
        border-radius: 16px;
        border: 1.5px solid $neutral-color-n-50;
        &.show {
            background-color: transparent;
        }
        &:hover {
            background-color: transparent;
        }
        border-right: 0px;
        padding-right: 0px;
        position: relative;
        z-index: 10;
        &::before {
            position: absolute;
            content: '';
            width: 1px;
            height: 16px;
            background-color: $neutral-color-n-40;
            right: -8px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .dropdown-toggle::after {
        width: 16px;
        height: 16px;
        border: none;
        vertical-align: middle;
        margin-left: 8px;
        background: url('../../../../public/images/icons/dropdown.svg')
            no-repeat center / 100%;
    }
    .react-tel-input .country-list {
        bottom: 100%;
        list-style: none !important;
        padding-left: 0px !important;
    }
}

// OTP INPUT CSS
.otp-verification {
    max-width: 460px;
    margin: 0 auto;
    .form-text.text-danger {
        text-align: center;
    }
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    .otp-wrapper {
        column-gap: 8px;
    }
    .otp-input {
        width: 100% !important;
        width: 100%;
        background-color: transparent;
        padding: 11px 15px;
        color: $neutral-color-n-10;
        font-size: 16px;
        -webkit-appearance: none;
        line-height: 24px;
        border-radius: 16px;
        border: 1.5px solid $neutral-color-n-50;
        appearance: none;
        &:focus {
            box-shadow: none;
            outline: none;
        }
    }
}
.language-selector {
    .custom-option {
        .custom-icon {
            max-width: 36px;
        }
    }
}
// UPLOADER CSS
.uploader {
    text-align: center;
    padding-bottom: 32px;
    p {
        font-size: 16px;
        line-height: 24px;
        color: $neutral-color-n-40;
        padding-top: 8px;
    }
}
.upload-input {
    position: relative;
    width: 44%;
    margin: 0 auto;
    aspect-ratio: 1;
    border-radius: 50%;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    input {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
    }
}
// TEXT AREA
.textarea.form-control {
    min-height: 84px;
}
::-webkit-input-placeholder {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: $neutral-color-n-40;
}
@media (max-width: 1399px) {
}
@media (max-width: 1199px) {
}
@media (max-width: 992px) {
    .custom-dropdown.language-selector {
        .css-13cymwt-control,
        .css-t3ipsp-control {
            padding: 6px 12px;
        }
    }
    .language-selector {
        .custom-option {
            .custom-icon {
                max-width: 32px;
            }
        }
    }
}
@media (max-width: 767px) {
    .language-selector .css-13cymwt-control,
    .language-selector .css-16xfy0z-control,
    .language-selector .css-t3ipsp-control {
        border-radius: 12px;
    }
    .language-selector {
        .custom-option {
            .custom-icon {
                max-width: 30px;
            }
        }
    }
    .form-control,
    .css-16xfy0z-control {
        padding: 10px 14px !important;
    }
    .mobile-input .dropdown-toggle {
        padding: 10px 14px;
        padding-right: 0px;
    }
    .custom-dropdown .css-13cymwt-control,
    .custom-dropdown .css-t3ipsp-control {
        padding: 10px 14px;
    }
    .date-picker .date-input input {
        padding: 11px 14px;
    }
}

@media (max-width: 767px) {
    .custom-dropdown .css-1nmdiq5-menu {
        padding: 10px;
    }
}

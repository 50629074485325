.speak-now {
    display: flex;
    flex-direction: column;
    height: 100%;
    .content-wrapper {
        flex: 1;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
    }
    .call-wheeler {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        max-width: 364px;
        margin: 0 auto;
        width: 100%;
    }
}
.speak-content {
    padding-bottom: 20px;

    margin: 0 auto;
}
// CALL WHEELER
.wheel-container {
    position: relative;
    padding-top: 100%;
    transition: all ease 3s;
}
.wheel-arrow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.wheeler-wrapper {
    text-align: center;
    overflow: hidden;
    padding: 0 20px;
    width: 100%;
    .stand {
        margin-top: -50px;
        img {
            min-width: 210px;
        }
    }
    .isRotate {
        animation: spin 0.6s linear infinite;
    }
}
.wheeler-action {
    padding-top: 14px;
    width: 100%;
    button {
        width: 100%;
    }
    .count-down {
        .counter {
            display: flex;
            align-items: center;
            justify-content: center;
            & > div {
                min-width: 96px;
                text-align: center;
                p {
                    color: $neutral-color-n-30;
                }
            }
        }
    }
}
.circle-wrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
    border: 16px solid #0080ff;
    border-radius: 50%;
    background-color: #66b3ff;
    position: relative;
    position: absolute;
    top: 0;
    left: 0;
    transition: all ease 8s;
}

.triangle {
    position: absolute;
    height: 50%;
    width: 50%;
    transform-origin: right bottom;
    transition: all ease 4s;
    top: 0px;
    left: 0px;
    clip-path: polygon(0 100%, 100% 100%, 0 0);
}

.triangle:nth-child(1) {
    background-color: #39f;
}

.triangle:nth-child(2) {
    transform: rotate(45deg);

    background-color: #cce6ff;
}

.triangle:nth-child(3) {
    transform: rotate(90deg);
    background-color: #06c;
}

.triangle:nth-child(4) {
    transform: rotate(135deg);
    background-color: #66b3ff;
}

.triangle:nth-child(5) {
    transform: rotate(180deg);
    background-color: #39f;
}

.triangle:nth-child(6) {
    transform: rotate(225deg);
    background-color: #cce6ff;
}

.triangle:nth-child(7) {
    transform: rotate(270deg);
    background-color: #06c;
}

.triangle:nth-child(8) {
    transform: rotate(315deg);
    background-color: #66b3ff;
}

.circle-wrapper .triangle .dp {
    display: flex;
    position: absolute;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    top: calc(75% - 16px);
    left: calc(50% - 10px);
    transform-origin: left bottom;
    transform: rotate(290deg);
    overflow: hidden;
    border-radius: 50%;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

// Rules
.rules {
    display: flex;
    flex-direction: column;
    height: 100%;
    .content-wrapper {
        background-color: $primary-color-p-20;
        color: $white;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .rules-content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        h1 {
            color: $white;
        }
        .rules-icon {
            max-width: 104px;
        }
    }
    .rules-points {
        list-style: initial;
        padding-left: 24px;
        li {
            font-size: 22px;
            line-height: 32px;
        }
    }
    .rule-logo {
        text-align: end;
    }
}
@media (max-width: 1299px) {
    .speak-now .call-wheeler {
        max-width: 300px;
    }
}
@media (max-width: 992px) {
    .rules .rules-points li {
        font-size: 20px;
        line-height: 28px;
    }
    .rules .rules-content .rules-icon {
        max-width: 90px;
    }
    .rules .rule-logo {
        text-align: left;
        img {
            max-width: 200px;
        }
    }
}

@media (max-width: 767px) {
    .speak-now .content-wrapper {
        display: block;
        &.spinner-wrapper {
            display: flex;
            flex-direction: column-reverse;
            row-gap: 20px;
        }
    }
    .rules .rules-points li {
        font-size: 18px;
        line-height: 26px;
    }
    .rules .rules-content .rules-icon {
        max-width: 80px;
    }
    .rules .rule-logo {
        text-align: left;
        img {
            max-width: 180px;
        }
    }
}

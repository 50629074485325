$dark-blue: #003366;
$neutral-color-n-10: #1a1a1a;
$neutral-color-n-50: #808080;
$neutral-color-n-80: #ccc;
$primary-color-p-50-main: #0080ff;
$neutral-color-n-30: #4d4d4d;
$neutral-color-n-40: #666;
$white: #fff;
$neutral-color-n-20: #333;
$neutral-color-n-70: #b3b3b3;
$primary-color-p-70: #66b3ff;
$state-color-error: #ed461e;
$primary-color-p-95: #e5f2ff;
$primary-color-p-20: #036;
$neutral-color-n-96: #f5f5f5;
$primary-color-p-70: #66b3ff;
$neutral-color-n-90: #e6e6e6;
$neutral-color-n-60: #999;
$state-color-warning: #f2a603;
$state-color-success: #2db452;
$white: white;
$primary-color-p-90: #cce6ff;
$neutral-color-n-95: #f2f2f2;
$global-color-black: #000;

.dashboard-container {
    min-height: 100vh;
    min-height: 100svh;
    max-height: 100vh;
    max-height: 100svh;
    display: flex;
    overflow: hidden;
}
.sidebar {
}
.screens-wrapper {
    flex: 1;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    overflow: auto;
    &.chat-screen {
        max-height: 100vh;
        .content-wrapper {
            max-height: calc(100% - 56px);
            min-height: 400px;
        }
        .screens-container {
            max-height: calc(100vh - 130px);
            min-height: 600px;
            &.full-screen {
                // max-height: fit-content;
                // min-height: unset;
                .chat-section {
                    overflow: auto;
                }
            }
        }
    }
}
.video-call-wrapper {
    user-select: none;
    &.full-screen {
        padding: 0px;
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 999;
        top: 0;
        left: 0;
        border-radius: 0px;
        .str-video {
            height: 100%;
        }
        .call-wrapper {
            aspect-ratio: unset;
            height: 100%;
            max-width: 100%;
        }
        .str-video__participant-view {
            border-radius: 0px;
        }
        .str-video__speaker-layout {
            padding-inline: 0px;
        }
    }
}
.str-video__participant-view > .str-video__call-controls__button {
    display: none !important;
}
.str-video__speaker-layout
    .str-video__speaker-layout__spotlight
    .str-video__video {
    object-fit: cover;
}
.call-wrapper
    .str-video__speaker-layout__wrapper
    .str-video__speaker-layout
    .str-video__speaker-layout__spotlight
    .str-video__participant-view {
    height: 100%;
}
.screens-container {
    width: 100%;
    padding: 24px;
    position: relative;
    flex: 1;
    background-color: $neutral-color-n-96;
}
.content-wrapper {
    background-color: $white;
    padding: 24px;
    position: relative;
    border-radius: 24px;
    &.border-wrapper {
        border: 6px solid rgba(0, 102, 204, 0.5);
    }
}
@media (max-width: 992px) {
    .content-wrapper {
        padding: 20px;
    }
}
@media (max-width: 767px) {
    .content-wrapper {
        padding: 16px;
        border-radius: 16px;
    }
    .screens-container {
        padding-inline: 12px;
    }
    .content-wrapper.border-wrapper {
        border: 4px solid rgba(0, 102, 204, 0.5);
    }
}

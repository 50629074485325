* {
    box-sizing: border-box;
}
// html {
//     max-height: 100vh;
// }
.mobile-input .react-tel-input .form-control {
    padding-left: 44px !important;
    background-color: transparent;
}
.modal-dialog-scrollable .modal-content {
    max-height: 90%;
}
.primary-btn {
    padding: 9px 32px;
    line-height: 26px;
    font-size: 18px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 197px;
    min-height: 48px;
    border-radius: 24px;
    background-color: $primary-color-p-50-main;
    border: 2px solid $primary-color-p-50-main;
    color: $white;
    transition: all 0.2s ease-in-out;
    &:hover {
        color: $primary-color-p-50-main;
        background-color: transparent;
        .loading-dots.white .dot {
            background-color: $primary-color-p-50-main;
        }
    }
    &.outline-btn {
        color: $primary-color-p-50-main;
        background-color: transparent;
        &:hover {
            background-color: $primary-color-p-50-main;
            color: $white;
        }
    }
    &.outline-btn-white {
        color: $white;
        background-color: transparent;
        border-color: $white;
        &:hover {
            background-color: $primary-color-p-50-main;
            color: $white;
            border-color: $primary-color-p-50-main;
        }
    }
    &.red-outline {
        color: $state-color-error;
        background-color: transparent;
        min-width: 135px;
        border-color: $state-color-error;
        &:hover {
            background-color: $state-color-error;
            color: $white;
        }
    }
    &.disable-btn {
        cursor: not-allowed;
        background-color: rgb(140, 140, 140);
        border-color: rgb(140, 140, 140);
        color: white;
        opacity: 0.8;

        &:hover {
            background-color: rgb(140, 140, 140);
            color: $white;
        }
    }
    &.green-btn {
        background-color: $state-color-success;
        border-color: $state-color-success;
        &:hover {
            background-color: transparent;
            color: $state-color-success;
        }
    }
    &.grey-btn {
        background-color: transparent;
        color: $neutral-color-n-40;
        border-color: $neutral-color-n-40;
        &:hover {
            background-color: transparent;
            color: $neutral-color-n-40;
        }
    }
}

.blue-text {
    color: $primary-color-p-50-main;
}

.grey-bg {
    background-color: $neutral-color-n-96;
    border-radius: 24px;
    padding: 24px;
}
.simple-btn {
    border: none;
    background-color: transparent;
    padding: 0px;
}
.modal {
    z-index: 1000000;
}
.modal-backdrop.show {
    z-index: 999999;
}

.bread-action {
    display: flex;
    gap: 16px;
    .outline-btn {
        min-width: 130px;
        padding: 6px 20px;
        min-height: 44px;
        &.red-outline {
            min-width: auto;
        }
    }
}
// BOOTSTRAP DROPDOWN
.dropdown-menu.show {
    padding: 16px;
    margin-top: 6px;
    border-radius: 16px;
    border: 1.5px solid $neutral-color-n-80;
    .dropdown-item {
        padding: 8px 16px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        column-gap: 8px;
        color: $neutral-color-n-40;
        &:hover {
            background-color: $primary-color-p-50-main;
            color: $white;
        }
    }
}
.tutorial-screen {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.659);

    &.speak-intro {
        .tutorial {
            top: 313px;
            left: 256px;
            .arrow {
                transform: rotate(297deg);
                padding-bottom: 30px;
            }
        }
    }
    &.chat {
        .tutorial {
            top: 376px;
            left: 258px;
            .arrow {
                transform: rotate(297deg);
                padding-bottom: 30px;
            }
        }
    }
    &.reward {
        .tutorial {
            top: 441px;
            left: 258px;
            .arrow {
                transform: rotate(297deg);
                padding-bottom: 30px;
            }
        }
    }
    &.appointments {
        .tutorial {
            top: 211px;
            left: 263px;
            .arrow {
                transform: rotate(297deg);
                padding-bottom: 30px;
            }
        }
    }
    &.availability {
        .tutorial {
            top: 274px;
            left: 179px;
            .arrow {
                transform: rotate(297deg);
                padding-bottom: 30px;
            }
        }
    }
}
.instr-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 32px;
    position: absolute;
    bottom: 48px;
    width: 100%;
    button {
        min-width: 122px;
    }
}
.tutorial {
    max-width: 260px;
    text-align: center;
    position: absolute;
    &::after {
        background: url('../../../../public/images/home-tut-arrow.svg')
            no-repeat center / contain;
        content: '';
        position: absolute;
    }
    &.home-tut {
        top: 260px;
        right: 200px;
        &::after {
            top: -60px;
            right: -100px;
            width: 100px;
            height: 100px;
        }
    }
    &.availability-instr {
        top: 400px;
        left: 120px;
        &::after {
            top: -80px;
            right: 10%;
            transform: translateY(-50%) rotate(280deg);

            width: 100px;
            height: 100px;
        }
        &.rewardsInstr-instr {
            top: 560px;
        }
    }
    p.lg {
        color: $white;
        text-align: center;
        padding-bottom: 16px;
    }
    button {
        min-width: auto;
        margin: 0 auto;
    }
}
.btn.show,
.btn:first-child:active {
    border-color: $neutral-color-n-50;
}
.form-space {
    row-gap: 16px;
}
.dropdown-menu {
    max-height: 220px;
    overflow: auto;
}
.pt-24 {
    padding-bottom: 24px;
}
.error {
    color: $state-color-error !important;
}
.grey-text {
    color: $neutral-color-n-40;
}
.blue-box {
    padding: 16px;
    border-radius: 24px;
    background-color: $primary-color-p-50-main;
}
.fancy-box-wrapper {
    height: 100%;
    a {
        display: inline-block;
        height: 100%;
        width: 100%;
    }
}
.fancybox__container .fancybox__caption {
    background-color: $primary-color-p-50-main;
    padding: 10px 16px !important;
    color: $white;
    border-radius: 10px;
}
.dropdown-toggle::after {
    content: unset;
}
.f-600 {
    font-weight: 600;
}
.f-700 {
    font-weight: 700;
}
.f-500 {
    font-weight: 500;
}
a {
    text-decoration: none;
}
ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
}
.demo-call {
    display: flex;
    flex-direction: column;
    height: 100%;
    .content-wrapper {
        flex: 1;
    }
}
@media (max-width: 767px) {
    .grey-bg {
        padding: 16px;
        border-radius: 16px;
    }
    .primary-btn {
        min-width: 130px;
        padding: 6px 20px;
        min-height: 44px;
        &.red-outline {
            min-width: 130px;
        }
    }
}

.c-pointer {
    cursor: pointer;
}

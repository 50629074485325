// STEPS INDICATOR
.steps-indicators {
    display: flex;
    column-gap: 16px;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: $white;
    padding: 10px;
    z-index: 1;
}
.lavel {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $neutral-color-n-70;
    &.active {
        width: 32px;
        height: 32px;
        font-size: 18px;
        line-height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $primary-color-p-50-main;
        color: $white;
    }
    &.filled {
        background-color: $primary-color-p-70;
    }
}
.steps-length {
    display: flex;
    justify-content: center;
    column-gap: 8px;
    span {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: $neutral-color-n-70;
        display: inline-block;
    }
}

// ACCOUNT TITLE
.account-title {
    text-align: center;
    padding-bottom: 24px;
    p {
        padding-top: 16px;
        color: $neutral-color-n-30;
    }
}

// CREATE ACCOUNT
.account-container {
    padding-top: 40px;
}
.padding-top-120 {
    padding-top: 120px;
}

// STEP ONE
.account-type {
    display: flex;
    column-gap: 24px;
    & > div {
        flex: 1;
        position: relative;
        text-align: center;
        input {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0;
        }
        input[type='radio']:checked + .type {
            border: 4px solid $primary-color-p-50-main;
            p {
                color: $primary-color-p-50-main;
            }
        }
    }
    .type {
        padding: 20px 27px;
        border-radius: 16px;
        border: 4px solid $neutral-color-n-80;
        p {
            padding-top: 12px;
            color: $neutral-color-n-20;
        }
    }
}
.address {
    padding-top: 32px;
}

// STEP TWO
.error-msg {
    display: flex;
    justify-content: center;
    column-gap: 8px;
    padding: 11px;
    background: #fce0d9;
    border-radius: 16px;
    p {
        font-size: 16px;
        line-height: 24px;
    }
    &.grey-error {
        background: rgba(128, 128, 128, 0.146);
    }
}
.form-footer {
    text-align: center;
    padding-block: 30px;
    .sm {
        color: $neutral-color-n-50;
    }
}
.lang-wrapper {
    position: sticky;
    top: 10px;
    z-index: 3;
}
.input-wrapper {
    position: relative;
}
.pass-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
}
.left-animation-screen {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $primary-color-p-20;
    padding: 30px 50px;
    position: sticky;
    top: 0;
    .logo {
        z-index: 99;
        position: relative;
        display: inline-block;
        a {
            display: inline-block;
        }
    }
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100px;
        height: 50px;
        background: url('../../../../public/images/bottom-center.svg') no-repeat;
    }
    .top-vector-bg {
        position: absolute;
        top: 0;
        left: 0;
        max-width: 50%;
    }
    .loop-content {
        max-width: 620px;
        padding-top: 160px;
        video {
            aspect-ratio: 1/0.6;
        }
    }
    .text-animation {
        max-width: 510px;
        .animation {
            font-weight: 400;
            color: $neutral-color-n-90;
            padding-top: 16px;
            width: calc(100% - 60px);
            display: block; /* Fallback for non-webkit */
            padding-right: 20px;
            .index-module_type__E-SaG {
                position: relative;
                padding-right: 10px;
            }
            .index-module_type__E-SaG::after {
                width: 20px;
                height: 20px;
                content: '';
                background-color: $white;
                display: inline-block;
                margin-left: 10px;
                border-radius: 50%;
                position: absolute;
                bottom: 10px;
            }
        }
    }
    .logo {
        img {
            max-width: 128px;
        }
    }
    .text-heading {
        font-size: 40px;
        font-weight: 700;
        position: relative;
        line-height: 50px;
        position: relative;
        z-index: 1;
        color: $white;
        &::after {
            content: '';
            width: 90px;
            height: 90px;
            position: absolute;
            right: -40px;
            top: -40px;
            background: url('../../../../public/images/center.svg') no-repeat;
            z-index: -1;
        }
    }
    .left-screen-footer {
        display: flex;
        padding-top: 20px;
        column-gap: 16px;
        row-gap: 8px;
        flex-wrap: wrap;
        a {
            color: $white;
        }
    }
}
// RESPONSIVE START HERE
@media (max-width: 1399px) {
    .padding-top-120 {
        padding-top: 90px;
    }
}
@media (max-width: 1199px) {
    .padding-top-120 {
        padding-top: 80px;
    }
}
@media (max-width: 992px) {
    .left-animation-screen .text-heading {
        font-size: 34px;
        line-height: 38px;
    }
    .padding-top-120 {
        padding-top: 60px;
    }
}
@media (max-width: 767px) {
    .text-animation .index-module_type__E-SaG::after {
        bottom: 8px !important;
    }
    .left-animation-screen .logo img {
        max-width: 100px;
    }
    .left-animation-screen .text-heading {
        font-size: 32px;
        line-height: 38px;
    }
    .left-animation-screen .text-animation .animation {
    }
    .left-animation-screen
        .text-animation
        .animation
        .index-module_type__E-SaG::after {
        width: 16px;
        height: 16px;
    }
    .left-animation-screen {
        .loop-content {
            padding-top: 100px;
        }
    }
    .steps-indicators {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        padding: 10px;
        background-color: $white;
    }

    .padding-top-120 {
        padding-top: 50px;
    }
    .address {
        padding-top: 30px;
    }
    .left-animation-screen .text-animation .animation {
        font-size: 20px;
    }
}
@media (max-width: 575px) {
    .padding-top-120 {
        padding-top: 30px;
    }
    .steps-indicators {
        column-gap: 10px;
    }
    .type {
        padding: 16px 20px !important;
        img {
            max-width: 100px;
        }
    }
}

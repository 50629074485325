@keyframes dot-keyframes {
    0% {
        opacity: 0.2;
        transform: scale(1, 1);
    }

    50% {
        opacity: 1;
        transform: scale(1.4, 1.4);
    }

    100% {
        opacity: 0.2;
        transform: scale(1, 1);
    }
}

.loading-dots {
    text-align: center;
    width: 100%;
    display: flex;
    gap: 8px;
    justify-content: center;
    width: auto;

    .dot {
        animation: dot-keyframes 1s infinite ease-in-out;
        background-color: black;
        border-radius: 10px;
        display: inline-block;
        height: 6px;
        width: 6px;
        &:nth-child(1) {
            animation-delay: 0.3s;
        }
        &:nth-child(2) {
            animation-delay: 0.2s;
        }

        &:nth-child(3) {
            animation-delay: 1s;
        }
    }
    &.white {
        .dot {
            background-color: white;
        }
    }
}
.chat-loader {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

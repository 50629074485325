.chat-page {
    display: flex;
    flex-direction: column;
    height: 100%;
    .content-wrapper {
        flex: 1;
    }
}
.call-toaster {
    .call-chat-booster {
        background-color: $primary-color-p-50-main;
        text-align: left;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
        padding: 10px;
        color: $white;
        border-radius: 12px;
        max-width: 333px;
    }
    .simple-btn {
        color: $white;
        margin-top: 8px;
    }
    .toaster-checkbox {
        display: flex;
        justify-content: flex-end;
        form {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .form-check-input {
                width: 20px;
                height: 20px;
                margin-top: 2px;
                background-color: transparent;
                margin-right: 8px;
                &:checked[type='checkbox'] {
                    background-image: url('../../../../public/images/blue_check_ic.svg') !important;
                    background-color: $white;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-repeat: 16px;
                }
            }
            label {
                color: $white;
                font-size: 16px;
                margin: 0px;
            }
        }
    }
    .form-check-input:checked[type='checkbox'] {
        background-image: url('../../../../public/images/blue_check_ic.svg') !important;
    }
}
.str-video__call-controls__button {
    left: 20px !important;
    right: unset !important;
}
.boosterChat {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 99;
    .timer {
        margin-bottom: 10px;
        text-align: end;
        p {
            display: inline-block;
            background-color: hsla(0, 0%, 100%, 0.333);
            padding: 6px 12px;
            border-radius: 20px;
            color: $white;
        }
    }
}
.voice-note {
    display: inline-flex;
    align-items: center;
    background-color: $primary-color-p-50-main;
    padding: 0px 8px;
    gap: 4px;
    border-radius: 16px;
    justify-content: space-between;
    min-width: 200px;
    button {
        border: 0px;
        background-color: transparent;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    p {
        color: #fff !important;
    }
}

.chat-wrapper {
    height: 100%;
    display: flex;
    column-gap: 24px;
    .chat-users {
        padding: 16px;
        background-color: $neutral-color-n-96;
        border-radius: 24px;
        width: 40%;
        .custom-tabs .tab-content {
            overflow: auto;
            padding-right: 4px;
            flex: 1;
        }
    }
    .chat-section {
        width: 60%;
    }
}

// WELCOME SCREEN
.welcome-screen {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    .chat-logo {
        padding-top: 56px;
        padding-bottom: 64px;
    }
    .welcome-wrapper {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
    }
    .dp {
        max-width: 140px;
        aspect-ratio: 1;
        overflow: hidden;
        border-radius: 50%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
.voiceRecorder {
    display: flex;
    height: 100%;
    max-height: 50px;
    justify-content: space-between;
    align-items: center;
    background-color: $primary-color-p-50-main;
    padding: 10px;
    width: 60px;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    border-radius: 16px;
    position: absolute;
    right: 0px;
    max-width: 100%;
    background-color: transparent;
    z-index: 6;
    top: 0;
    .audio-recorder-timer {
        color: $white;
    }
    button {
        padding: 0px;

        overflow: visible !important;
    }
    .voiceRecorder__recordButton {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 16px;
        background-color: #f5f5f5;
    }
    .audio-recorder {
        flex-direction: row-reverse;
        background-color: transparent;
        box-shadow: none;
        padding-right: 8px;

        canvas {
            height: 30px;
            filter: brightness(10);
            aspect-ratio: unset !important;
        }
        width: 100%;
        img {
            display: none;
        }
    }
}
.voiceWrapper {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    button {
        min-width: 40px;
    }
}
.chat-box {
    display: flex;
    flex-direction: column;
    .chat-container {
        height: calc(100% - 73px);
        display: flex;
        flex-direction: column;
        .msg-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: end;
            height: calc(100% - 84px);
            position: relative;
            .no-msg {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: 100px;
            }
            .massages {
                padding: 0 24px;
                overflow: auto;
                padding-bottom: 0px;
                .sm {
                    padding: 12px;
                    border-radius: 16px;
                }
                .user-msg {
                    max-width: 70%;
                    margin-left: auto;
                    display: flex;
                    flex-direction: column;
                    align-items: end;
                    .xsm {
                        color: $neutral-color-n-40;
                    }
                    .sm {
                        border-radius: 16px 2px 16px 16px;
                        background-color: $primary-color-p-90;
                        margin-bottom: 4px;
                    }
                    &.user-2 {
                        margin-right: auto;
                        margin-left: unset;
                        align-items: start;
                        .sm {
                            border-radius: 2px 16px 16px 16px;
                            background-color: $neutral-color-n-90;
                        }
                    }
                    .media-msg {
                        max-width: 140px;
                        width: 100%;
                        aspect-ratio: 1;
                        border-radius: 16px;
                        overflow: hidden;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                        & > div {
                            height: 100%;
                            a {
                                display: inline-block;
                                height: 100%;
                                width: 100%;
                            }
                        }
                    }
                }
                & > div:not(:last-child) {
                    margin-bottom: 16px;
                }
            }
        }
        .chat-input {
            border-radius: 16px;
            padding-left: 16px;
            border: 1px solid $neutral-color-n-80;
            width: 100%;
            display: flex;
            position: relative;
            .voiceRedWrapper {
                position: absolute;
                width: 100%;
                height: 100%;
                right: 0;
                top: 0;
                z-index: 5;
            }
            .preview {
                position: absolute;
                top: -110px;
                left: 0;
                .preview-media {
                    max-width: 100px;
                    position: relative;
                    aspect-ratio: 1;

                    .close-icon {
                        width: 30px;
                        height: 30px;
                        padding: 0px;
                        position: absolute;
                        right: -15px;
                        top: -15px;
                        z-index: 2;
                        img {
                            width: 100%;
                        }
                    }
                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 10px;
                        overflow: hidden;
                    }
                }
            }
            input {
                flex: 1;
                padding: 12px 40px 12px 8px;
                background-color: transparent;
                border: none;
                &:focus {
                    outline: none;
                }
            }
            button {
                border: none;
                background-color: transparent;
                position: relative;
                overflow: hidden;
                input {
                    position: absolute;
                    width: 200px;
                    height: 200px;
                    opacity: 0;
                    padding: 0px;
                    top: 0;
                    right: 0;
                    z-index: 2;
                    cursor: pointer;
                }
            }
            &.deleted-account-message {
                height: 48px;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
.chat-box-users {
    .user-item {
        padding: 12px 16px;
        display: flex;
        column-gap: 8px;
        background-color: $white;
        border: 1px solid $neutral-color-n-80;
        border-radius: 16px;
        cursor: pointer;
        &:not(:last-child) {
            margin-bottom: 16px;
        }
        &.active-user {
            background-color: $primary-color-p-90;
            border-color: $primary-color-p-90;
        }
    }
    .name-time {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        p:not(:last-child) {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1;
            -webkit-box-orient: vertical;
            flex: 1;
            min-width: 80px;
        }
    }
    .chat-details {
        flex: 1;
    }
    .msg-numbers {
        width: 24px;
        height: 24px;
        min-width: 24px;
        border-radius: 50%;
        background-color: $primary-color-p-50-main;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
    }
    .last-msg {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .xsm {
            display: flex;
            column-gap: 4px;
            align-items: center;
        }
    }
    .user-dp {
        width: 48px;
        height: 48px;
        min-width: 48px;
        border-radius: 50%;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .chat-details {
        .xsm {
            color: $neutral-color-n-40;
            span {
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1; /* number of lines to show */
                line-clamp: 1;
                -webkit-box-orient: vertical;
            }
        }
    }
}
.report-popup {
    .custom-modal {
        max-width: 428px;
    }
    .popup-action {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
    }
}
.audio-prev {
    display: flex;
    gap: 10px;
    padding-inline: 24px;
    position: absolute;
    left: 0;
    bottom: calc(100% - 4px);
    width: 100%;
    background-color: #f5f5f5;
    z-index: 5;
    .voice-note {
        flex: 1;
    }
}
// CHAT BOX
.chat-box {
    background-color: $neutral-color-n-96;
    border-radius: 24px;
    height: 100%;
}
.chat-header {
    display: flex;
    min-height: 73px;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid $neutral-color-n-80;
    .user-dp-name {
        display: flex;
        align-items: center;
        column-gap: 12px;
        .dp {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    .header-action {
        button {
            border: none;
            background-color: transparent;
            padding: 0px;
        }
    }
}
.chat-toggle {
    button {
        &::after {
            content: unset;
        }
        &.show {
            background-color: $primary-color-p-50-main;
            img {
                filter: invert(1);
            }
        }
    }
    .dropdown-menu.show {
        border: none;
        box-shadow: -4px -4px 8px 0px rgba(0, 0, 0, 0.06),
            4px 4px 8px 0px rgba(0, 0, 0, 0.06);
    }
}
.input-chat {
    padding: 10px 24px 24px;
    column-gap: 8px;
    display: flex;
    position: relative;
    .send-btn {
        background-color: transparent;
        padding: 0px;
        border: 0px;
    }
}
.date-wise {
    text-align: center;
    padding-bottom: 24px;
    color: $neutral-color-n-40;
}
.chat-action {
    text-align: center;
    p.sm {
        padding: 0px !important;
    }
    button {
        font-weight: 600;
    }
}
.sensitive-msg {
    display: flex;
    align-items: center;
    column-gap: 8px;
}

// CHAT HISTORY
.history-action {
    display: flex;
    align-items: center;
    column-gap: 8px;
}
.history {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .time-ic {
        display: flex;
        align-items: center;
    }
}
.chat-history-wrapper {
    overflow: auto;
    padding: 16px 24px;

    .history-items {
        li {
            border-radius: 16px;
            padding: 12px 16px;
            border: 1px solid $neutral-color-n-80;
            background-color: $white;
            & > div:not(:last-child) {
                padding-bottom: 4px;
            }
            &:not(:last-child) {
                margin-bottom: 16px;
            }
        }
    }
}

// CHAT HISTORY TAB
.chat-box-users .user-item.call-duration-certificate {
    border: none;
    padding: 0px !important;
    background-color: transparent;
    .blue-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .total-time {
            p {
                color: $neutral-color-n-95;
            }
            h2 {
                color: $white;
            }
        }
    }
    .call-duration {
        margin: 0px;
        width: 100%;
    }
}

// CERTIFICATE BOX
.certificate-content {
    max-width: 517px;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    text-align: center;
    .simple-btn {
        position: absolute;
        top: 0px;
        left: 0px;
    }
    p {
        color: $neutral-color-n-30;
    }
}
.ct-social-ic {
    display: flex;
    column-gap: 16px;
    justify-content: center;
    padding-top: 40px;
}
.cert-details {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    color: $neutral-color-n-30;
}
.cert-heading {
    padding: 24px 0 32px 0;
    text-align: center;
}
.first-para {
    padding-bottom: 16px;
}
.certificate {
    height: 100%;
    display: flex;
    flex-direction: column;
    .content-wrapper {
        height: 100%;
    }
}
.certificate-wrapper {
    max-width: 980px;
    margin: 0 auto;
    .acadmy-name {
        text-decoration: underline;
    }
}
.signature-cert {
    display: flex;
    justify-content: space-between;
    padding-top: 48px;
    & > div {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        justify-content: space-between;
        img {
            margin: 0 auto;
            height: 128px;
        }
    }
}
@media (max-width: 1099px) {
    .chat-screen {
        .content-wrapper {
            padding: 16px;
        }
        .chat-wrapper {
            column-gap: 16px;
        }
        .chat-wrapper .chat-users {
            padding: 10px;
        }
        .chat-box .chat-container .msg-wrapper .massages {
            padding: 0 16px 10px;
        }
        .input-chat {
            padding: 10px 16px 24px;
        }
        .chat-box-users .user-item {
            padding: 10px;
        }
        .chat-box-users .user-dp {
            min-width: 40px;
            width: 40px;
            height: 40px;
        }
        .chat-header {
            padding: 12px;
            min-height: 65px;
        }
        .chat-box .chat-container .chat-input {
            padding-left: 10px;
        }
        .chat-box .chat-container .chat-input input {
            padding: 12px 10px 12px 8px;
        }
    }
}

@media (max-width: 767px) {
    .certificate-content {
        img {
            max-width: 280px;
        }
    }
    .chat-wrapper .chat-users {
        width: 100%;
    }
    .chat-wrapper .chat-section {
        width: 100%;
    }

    .chat-screen {
        .chat-box .chat-container .chat-input {
            padding-left: 4px;
            button {
                padding: 0 4px;
            }
        }
        .chat-box .chat-container .chat-input input {
            padding: 12px 10px 12px 8px;
        }
    }
    .ct-social-ic {
        a {
            img {
                max-width: 40px;
            }
        }
    }
    .chat-wrapper .chat-users {
        border-radius: 16px;
    }
}

.custom-modal {
    max-width: 344px;
    margin: 0 auto;
    .modal-body {
        padding: 32px;
        position: unset;
        padding-top: 48px;
    }
    .modal-content {
        border-radius: 24px;
        border: 0px;
    }
    .popup-close-btn {
        background-color: transparent;
        border: none;
        position: absolute;
        top: 16px;
        right: 16px;
        padding: 0px;
        img {
            width: 32px;
        }
    }
    &.report-modal {
        max-width: 428px;
    }
    &.legal-modal {
        width: calc(100% - 32px);
        max-width: 900px;
        .content-wrapper {
            text-align: justify;
        }
    }
}
.modal-content-wrapper {
    text-align: center;
}
.popup-title {
    font-weight: 600;
    padding: 20px 0 8px 0;
}
.popup-action {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    button {
        font-size: 18px;
        min-width: 170px;
    }
}
.report-popup {
    .report-dp {
        width: 172px;
        margin: 0 auto;
        aspect-ratio: 1;
        border-radius: 50%;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .blue-text {
        padding-bottom: 8px;
    }
    .popup-title {
        padding-bottom: 20px;
    }
}
@media (max-width: 767px) {
    .custom-modal {
        .modal-body {
            padding: 16px;
            padding-top: 48px;
        }
        &.report-modal {
            max-width: 400px;
        }
    }
    .custom-modal .modal-content {
        border-radius: 16px;
    }
}

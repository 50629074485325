html {
    scroll-behavior: smooth;
}
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    border-radius: 100px;
    cursor: pointer;
}

/* Track */
::-webkit-scrollbar-track {
    background: $neutral-color-n-90;
    border-radius: 10px;
    cursor: pointer;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #036;
    border-radius: 10px;
    cursor: pointer;
}

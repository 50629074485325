.account-setup {
    min-height: 100vh;
    display: flex;

    & > div {
        z-index: 2;
    }
}
// LOGIN SCREEN
.left-screen {
    background-color: $dark-blue;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 12px 40px;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    position: fixed;
    top: 0;
    img {
        max-width: 540px;
        width: 100%;
    }
}
.right-screen {
    padding: 40px 12px 40px;
    width: 50%;
    margin-left: auto;
}
.account-setup-wrapper {
    padding-top: 10px;
    max-width: 364px;
    margin: 0 auto;
    h1 {
        padding-bottom: 16px;
        color: $neutral-color-n-10;
    }
}

.login-form {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}
.action-btn {
    margin: 0 auto;
    margin-top: 20px;
    max-width: 364px;
}
.create-account {
    text-align: center;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    .simple-btn,
    a {
        line-height: 26px;
        font-size: 18px;
        margin-top: 16px;
        margin: 0 auto;
        color: $neutral-color-n-30;
    }
    &.action-btn {
        padding-bottom: 32px;
    }
}
// OTP VERIFICATION SCREEN
.opt-resent-wrapper {
    text-align: center;
    p {
        padding-bottom: 16px;
        color: $neutral-color-n-40;
        font-size: 16px;
        line-height: 24px;
        span {
            color: $neutral-color-n-20;
        }
    }
    button {
        color: $neutral-color-n-70;
        font-size: 18px;
    }
}
.edit-number {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
    color: $primary-color-p-50-main;
    font-weight: 500;
    font-size: 18px;
}
.max-width-cat {
    max-width: 554px;
}
.request-content {
    min-height: 700px;
    & > div {
        display: flex;
        flex-direction: column;
        gap: 16px;
        img {
            width: 100%;
        }
        h1 {
            color: $neutral-color-n-10;
        }
        p {
            color: $neutral-color-n-30;
        }
    }
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 364px;
    margin: 0 auto;
    text-align: center;
    gap: 24px;
}
.left-screen {
    .left-screen-thumbnail {
        width: 100%;
        object-fit: contain;
    }
}
.left-animation-screen {
    position: fixed !important;
    top: 0;
    height: 100%;
    width: 50%;
}
@media (max-width: 1399px) {
    .request-content > div img {
        max-width: 90%;
        margin: 0 auto;
    }
    .left-animation-screen .loop-content {
        padding-top: 60px !important;
    }
}
@media (max-width: 992px) {
    .left-screen .left-screen-thumbnail {
        max-width: 300px;
    }
    .account-setup-wrapper {
        padding-top: 80px;
    }
    .account-setup div img {
        max-width: 300px;
        margin: 0 auto;
        .request-content > div img {
            max-width: 300px;
        }
    }
}
@media (max-width: 1299px) {
    .account-setup div img {
        max-width: 300px;
        margin: 0 auto;
        .request-content > div img {
            max-width: 300px;
        }
    }
}
@media (max-width: 767px) {
    .left-animation-screen {
        position: unset !important;
        width: 100%;
    }
    .request-content {
        min-height: auto !important;
    }
    .account-setup div img {
        max-width: 300px;
        margin: 0 auto;
        .request-content > div img {
            max-width: 200px;
        }
    }
    .account-setup {
        max-height: 100%;
    }
    .right-screen {
        padding-top: 30px;
        width: 100%;
    }
    .custom-dropdown.language-selector {
        position: fixed;
        top: 20px;
        right: 12px;
        width: 100%;
    }
    .account-setup {
        flex-direction: column;
    }
    .account-setup-wrapper {
        padding-top: 30px;
    }
    .login-form {
        row-gap: 16px;
    }
    .opt-resent-wrapper p {
        padding-bottom: 10px;
    }
    .left-screen {
        position: unset;
        padding-top: 90px;
        width: 100%;
    }
}

.time-slots-wrapper {
    padding-top: 24px;
    .slot-title {
        color: $neutral-color-n-40;
        padding-bottom: 16px;
    }
}
.slots-scrollbar {
    max-height: 344px;
    overflow: auto;
    padding-right: 10px;
}
.day-slots {
    padding-bottom: 16px;
}
.fc-timegrid-event-harness-inset .fc-timegrid-event,
.fc-timegrid-event.fc-event-mirror,
.fc-timegrid-more-link {
    background-color: $primary-color-p-50-main;
}
.fc .fc-more-popover .fc-popover-body {
    max-height: 200px;
    overflow: auto;
}
.scrolling-horizontal {
    .fc .fc-scroller-liquid-absolute {
        position: relative;
        overflow: auto;
    }
    .fc .fc-view-harness-active > .fc-view {
        position: relative;
    }
}
.fc .fc-scrollgrid-section-body .fc-timegrid-body table {
    tbody {
        tr:nth-child(even) {
            td {
                border-top: 0px;
            }
        }
    }
}
.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
    max-height: 120px;
    overflow: auto;
}
@media (max-width: 767px) {
    .slots-scrollbar {
        max-height: 100%;
        overflow: auto;
        padding-right: 0px;
    }
}

// CUSTOM CALENDAR STYLES
.fc-day-past {
    .fc-h-event,
    .fc-timegrid-event {
        background-color: $neutral-color-n-90 !important;
        border-color: $neutral-color-n-90 !important;
        span {
            color: $neutral-color-n-60 !important;
        }
    }
}
.custom-calendar {
    .fc .fc-popover-body .fc-more-popover-misc {
        display: none;
    }
    .fc .fc-timegrid-slot-minor {
        border-style: solid;
    }
    .fc-theme-standard .fc-dayGridMonth-view {
        td {
            height: 140px !important;
            &.fc-day-past {
                .fc-h-event {
                    background-color: $neutral-color-n-90;
                    border-color: $neutral-color-n-90;
                    span {
                        color: $neutral-color-n-60;
                    }
                }
                .fc-daygrid-more-link {
                    color: $neutral-color-n-60 !important;
                }
            }
        }
    }
    .fc .fc-daygrid-day-top {
        justify-content: center;
    }
    .fc-toolbar-chunk {
        display: flex;
        align-items: center;
    }
    .fc-h-event {
        border-radius: 4px;
        background-color: $primary-color-p-50-main;
        &:has(.accepted) {
            background-color: #2db452;
            border-color: #2db452;
        }
    }

    .event-card {
        padding: 0px 0px;
        min-height: 22px;
        display: flex;
        flex-wrap: wrap;
        padding-left: 3px;
        justify-content: space-between;
        align-items: center;
        &:hover {
            .event-action {
                display: flex;
            }
        }
        .event-action {
            display: none;
            column-gap: 8px;
            position: relative;
            &::after {
                content: '';
                position: absolute;
                height: 90%;
                width: 1px;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                background-color: $white;
            }
        }

        span {
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px;
            color: $white;
        }
    }
    .fc-col-header-cell.fc-day.fc-day-past {
        a {
            opacity: 0.3;
        }
    }
    .fc-scrollgrid-section-header {
        .fc-scroller {
            overflow: hidden !important;
            border-bottom: 1px solid #ddd;
            background: #cce6ff;
            border-radius: 16px 16px 0 0;
        }

        table {
            thead {
                tr {
                    th {
                        border-bottom: none;
                        a {
                            font-size: 18px;
                            font-weight: 400;
                            line-height: 26px;
                            color: $neutral-color-n-40;
                            padding: 8px 0px;
                        }
                    }
                }
            }
        }
    }
    .fc-year-button.fc-button.fc-button-primary {
        background-color: transparent;
        border: 0px;
        color: #1a1a1a;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        pointer-events: none;
        line-height: 28px;
    }
    .fc .fc-daygrid-day-number {
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        color: $neutral-color-n-10;
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        .past-date {
            opacity: 0.3;
        }
    }

    .fc .fc-daygrid-day-frame {
        padding: 8px;
        cursor: pointer;
    }
    .fc .fc-daygrid-day.fc-day-today {
        background-color: transparent;
        .fc-daygrid-day-top {
            a {
                width: 32px;
                height: 32px;
                background-color: $primary-color-p-50-main;
                color: $white;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
            }
        }
    }
    .fc .fc-scrollgrid {
        border-radius: 16px;
        border: 1px solid $neutral-color-n-90;
    }
    .fc .fc-scrollgrid-section-liquid > td {
        border-color: transparent;
    }
    .fc .fc-scrollgrid-section-footer > *,
    .fc .fc-scrollgrid-section-header > * {
        border-color: transparent;
    }
    .fc-theme-standard td,
    .fc-theme-standard th {
        border-color: $neutral-color-n-90;
    }
    .fc-toolbar {
        & > div:nth-child(3) {
            display: flex;
            column-gap: 8px;
            button {
                margin: 0px;
                min-width: 100px;
                background-color: transparent;
                color: $neutral-color-n-40;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                border-radius: 100px;
                border: 0px;
                text-transform: capitalize;
                padding: 6px 16px;
                &.fc-button-active {
                    background-color: $primary-color-p-50-main;
                }
                &:focus {
                    box-shadow: none;
                }
            }
        }
        & > div:nth-child(1) {
            display: flex;
            column-gap: 24px;
            button {
                margin: 0;
                width: 40px;
                height: 40px;
                padding: 0px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $white;
                border-color: $neutral-color-n-90;
                &:focus {
                    box-shadow: none;
                }
                span {
                    color: $neutral-color-n-10;
                }
            }
            h2 {
                margin: 0;
                color: $neutral-color-n-10;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 28px;
            }
        }
    }
    .fc .fc-button-primary:not(:disabled).fc-button-active:focus,
    .fc .fc-button-primary:not(:disabled):active:focus {
        box-shadow: none;
    }
}
.fc-timeGridWeek-view {
    .fc-scrollgrid-section-header table thead tr th {
    }
}
.calendar-screen-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.content-wrapper.calendar-container {
    flex: 1;
    .custom-calendar {
        height: 100%;
    }
    .fc-media-screen {
        height: 100%;
    }
    .fc .fc-view-harness {
        height: 100% !important;
        min-height: 400px;
    }
    .fc-direction-ltr .fc-timegrid-now-indicator-arrow {
        border-color: $primary-color-p-50-main;
        border-bottom-color: transparent;
        border-top-color: transparent;
    }
    .fc .fc-timegrid-now-indicator-line {
        border-color: $primary-color-p-50-main;
    }
    .fc .fc-timegrid-col.fc-day-today {
        background-color: transparent;
        background: transparent;
    }
}
.calendar-popup {
    &.show {
        padding-block: 16px;
        z-index: 9999999;
    }
    &.custom-modal {
        max-width: 618px;
        width: calc(100% - 32px);
        height: 100%;
        .modal-body {
            position: unset;
            .popup-close-btn {
                background-color: $white;
                z-index: 99;
                border-radius: 16px 16px 0 0;
            }
        }
    }
    .modal-content-wrapper {
        text-align: unset;
    }
    .add-time-btn {
        border-radius: 16px;
        border: 1px solid $primary-color-p-50-main;
        background-color: transparent;
        padding: 7px;
        display: flex;
        align-items: center;
        column-gap: 4px;
        color: $primary-color-p-50-main;
    }
    .time-slot-wrapper {
        padding-block: 8px;
    }
    .slot-repeater-select {
        border-radius: 16px;
        margin-bottom: 8px;
        padding: 16px;
        margin-top: 12px;
        border: 1px solid $neutral-color-n-90;
        display: flex;
        flex-direction: column;
        row-gap: 16px;
    }
    .custom-pop-up-action {
        display: flex;
        gap: 24px;
    }
    .calendar-popup-action {
        padding-top: 24px;
        display: flex;
        justify-content: space-between;
        column-gap: 24px;
        .simple-btn {
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px;
            color: $neutral-color-n-30;
        }
        .primary-btn {
            min-width: auto;
            padding: 6px 16px;
            min-height: 42px;
            min-width: 108px;
        }
    }
    .custom-dropdown .css-13cymwt-control,
    .custom-dropdown .css-16xfy0z-control,
    .custom-dropdown .css-t3ipsp-control {
        border-radius: 8px;
    }
    .custom-dropdown .css-13cymwt-control,
    .custom-dropdown .css-16xfy0z-control,
    .custom-dropdown .css-t3ipsp-control {
        padding: 7px 15px;
        border-color: $neutral-color-n-80;
    }
    .dropdown-menu.show {
        border: none;
        box-shadow: -4px -4px 8px 0px rgba(0, 0, 0, 0.06),
            4px 4px 8px 0px rgba(0, 0, 0, 0.06);
    }

    .css-1nmdiq5-menu {
        top: unset;
        bottom: 100%;
    }
    .date-picker .react-datepicker__day .this-is-past {
        opacity: 0.3;
    }
}
.popup-close-btn {
    padding: 10px !important;
    width: 100%;
    left: 0;
    top: 0 !important;
    display: flex;
    justify-content: end;
    padding-bottom: 0px !important;
    background-color: $white !important;
    border-radius: 16px 16px 0 0;
}
.grey-bg .date-picker .react-datepicker__day span {
    position: relative;
    &::after {
        width: 5px;
        height: 5px;
        background-color: $state-color-error;
        content: '';
        position: absolute;
        bottom: -4px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 50%;
    }
}
.grey-bg .react-datepicker__day.react-datepicker__day--disabled span {
    &::after {
        content: unset;
    }
}

.time-selector-wrapper {
    padding-top: 8px;
    p.sm {
        color: $neutral-color-n-40;
    }
    .time-selector:not(:last-child) {
        padding-bottom: 8px;
    }
}
.dropdown {
    &.show {
        .dropdown-toggle {
            &::after {
                transform: translateY(-50%) rotate(180deg);
            }
        }
    }
}
.time-selector {
    display: flex;
    align-items: center;
    column-gap: 8px;
    .dropdown-toggle {
        background-color: transparent;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
        position: relative;
        color: $neutral-color-n-30;
        border-radius: 16px;
        min-width: 100px;
        padding: 8px 18px 8px 8px;
        border-color: $neutral-color-n-70;
        &::after {
            content: '';
            position: absolute;
            width: 20px;
            height: 20px;
            right: 8px;
            top: 50%;
            transform: translateY(-50%);
            border: none;
            background: url('../../../../public/images/icons/dropdown.svg')
                no-repeat center;
            background-size: 18px;
        }
        &:active {
            background-color: transparent;
            border-color: $primary-color-p-50-main;
            color: $neutral-color-n-30;
        }
    }
    .show.dropdown {
        .dropdown-toggle {
            border: 1px solid $primary-color-p-50-main;
        }
    }
}
.drop-repeat-wrapper {
    padding-bottom: 8px;
    p.sm {
        color: $neutral-color-n-40;
        padding-bottom: 8px;
    }
}
.repeat-day-week {
    display: flex;
    gap: 8px;
    .day-card {
        position: relative;
        input {
            position: absolute;
            width: 100%;
            height: 40px;
            top: 0;
            left: 0;
            opacity: 0;
            cursor: pointer;
        }

        .day {
            width: 40px;
            height: 40px;
            border-radius: 8px;
            background-color: $neutral-color-n-95;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px;
            color: $neutral-color-n-30;
        }
        input[type='checkbox']:checked ~ div {
            background-color: $primary-color-p-50-main;
            color: $white;
        }
    }
}
.repeat-dropdown {
    display: flex;
    column-gap: 20px;
    .custom-dropdown {
        min-width: 144px;
    }
}
.custom-calendar .fc-scrollgrid-section-header table thead tr th a {
    color: $neutral-color-n-10;
}
@media (max-width: 620px) {
    .custom-calendar .fc-toolbar > div:nth-child(1) {
        min-width: 300px;
        justify-content: space-between;
    }
}
@media (max-width: 767px) {
    .fc .fc-toolbar.fc-header-toolbar {
        flex-direction: column;
        gap: 6px;
    }
    .custom-calendar .event-card .event-action {
        display: flex;
        width: 100%;
        justify-content: center;
    }
    .custom-calendar {
        .event-card {
            justify-content: center;
        }

        .fc .fc-view-harness-active > .fc-view {
            overflow: auto;
        }
        .fc .fc-view-harness {
            min-height: 400px !important;
        }
        .fc-dayGridDay-view {
            overflow: hidden !important;
        }
        .fc-dayGridDay-view .fc-scrollgrid-liquid.fc-scrollgrid {
            width: 100% !important;
        }
    }
    .custom-calendar .fc .fc-scrollgrid {
        width: 840px;
    }
    .fc-timeGridDay-view .fc-scrollgrid {
        width: 100% !important;
    }
}

@media (max-width: 499px) {
    .repeat-day-week .day-card .day {
        width: 36px;
        height: 36px;
    }
    .slots-scrollbar {
        .slots-container {
            li {
                flex: 1;
                .time-slot-box {
                    width: 100%;
                    white-space: nowrap;
                }
            }
        }
    }
}

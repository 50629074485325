.main-sidebar {
    padding: 24px;
    background-color: $primary-color-p-20;
    display: inline-block;
    width: 270px;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
    justify-content: space-between;
}
.navbar-item {
    a,
    .logout-btn {
        padding: 12px 16px;
        width: 100%;
        border-radius: 16px;
        display: flex;
        align-items: center;
        gap: 8px;
        color: $white;
        transition: all 0.2s ease-in-out;
        span {
            display: inline-block;
            font-size: 18px;
            line-height: 26px;
            font-weight: 500;
        }
        &.active {
            background-color: $primary-color-p-50-main;
        }
        &:hover {
            background-color: $primary-color-p-50-main;
        }
    }
}
.navigation {
    padding-top: 48px;
    ul li:not(:last-child) {
        margin-bottom: 4px;
    }
    .msg-counter {
        width: 20px;
        height: 20px;
        color: $white;
        font-size: 12px;
        line-height: 14px;
        background-color: $primary-color-p-50-main;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        transition: all 0.2s ease-in-out;
    }
    ul li a {
        &.msg-counter-link {
            display: flex;
            justify-content: space-between;
            align-items: center;
            &.active,
            &:hover {
                .msg-counter {
                    background-color: $white;
                    color: $primary-color-p-50-main;
                }
            }
        }
    }
}
.mobile-logo {
    img {
        max-width: 70px;
    }
}
.mobile-profile {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    .mobile-dp {
        width: 50px;
        aspect-ratio: 1;
        border-radius: 50%;
        overflow: hidden;
        img {
            max-width: 50px;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .user-name {
        color: $white;
        img {
            filter: brightness(6);
        }
    }
}

@media (max-width: 767px) {
    .sidebar.activeHeader .main-sidebar {
        width: 100%;
    }
    .navigation {
        padding-top: 20px;
    }
}

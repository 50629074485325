.footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 12px 24px;
    column-gap: 40px;
    white-space: nowrap;
    p.sm {
        color: $neutral-color-n-10;
    }
}
.footer-nav ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    li {
        padding: 0px 12px;
        a {
            color: $neutral-color-n-40;
            transition: all 0.2s ease-in-out;
            &:hover {
                color: $primary-color-p-50-main;
            }
            &.active {
                color: $primary-color-p-50-main;
            }
        }
    }
}

@media (max-width: 1262px) {
    .footer {
        flex-direction: column-reverse;
        justify-self: center;
        align-items: center;
        row-gap: 12px;
    }
    .footer-nav ul {
        justify-content: center;
    }
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import './helper/variable';
@import './helper/typography';
@import './helper/common';
@import './component-scss/_formType.scss';
@import './pages-scss/accountSetup';
@import './pages-scss/CreateAccount';
@import './component-scss//CommonComponents';
@import './dashboard/mainDashboard';
@import './component-scss/sidebar';
@import './component-scss/dashHeader';
@import './component-scss/footer';
@import './pages-scss/home';
@import './component-scss/tableData';
@import './component-scss/modal';
@import './pages-scss/availability';
@import './helper/scrollbar';
@import './pages-scss/profileManagement';
@import './pages-scss/appointments';
@import './pages-scss/chat';
@import './pages-scss/speakNow';
@import './pages-scss//rewards';
@import './pages-scss//cmsPages';
@import './component-scss/call';
@import './pages-scss/user-instruction';
body {
    font-family: 'Noto Sans', sans-serif;
    color: $neutral-color-n-10;
}

.logout-button {
    &:hover {
        background-color: red !important;
    }
}

h1 {
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    margin: 0px;
    color: $neutral-color-n-10;
}
h1.lg {
    font-size: 40px;
    line-height: 48px;
}
h2 {
    line-height: 32px;
    font-size: 24px;
    margin: 0px;
}
h3 {
    font-size: 24px;
    line-height: 32px;
    margin: 0px;
}
h4 {
    font-size: 22px;
    line-height: 32px;
    margin: 0px;
}
p.sm {
    font-size: 16px;
    line-height: 24px;
}
p.xsm {
    font-size: 14px;
    line-height: 20px;
}
p {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    margin: 0px;
}
p.lg {
    font-size: 20px;
    line-height: 28px;
}
label {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: $neutral-color-n-40;
}

@media (max-width: 1199px) {
    h1 {
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        line-height: 38px;
        margin: 0px;
    }
    h1.lg {
        font-size: 36px;
        line-height: 48px;
    }
    h2 {
        line-height: 30px;
        font-size: 23px;
    }
    h3 {
        font-size: 23px;
        line-height: 31px;
        margin: 0px;
    }
    p {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        margin: 0px;
    }
    label {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: $neutral-color-n-40;
    }
}

@media (max-width: 992px) {
    h1 {
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 36px;
        margin: 0px;
    }
    h1.lg {
        font-size: 32px;
        line-height: 48px;
    }
    h2 {
        line-height: 30px;
        font-size: 22px;
    }
    h3 {
        font-size: 22px;
        line-height: 30px;
        margin: 0px;
    }
    p {
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px;
        margin: 0px;
    }
    label {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: $neutral-color-n-40;
    }
    p.lg {
        font-size: 19px;
        font-weight: 500;
        line-height: 26px;
    }
}

@media (max-width: 767px) {
    h1 {
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        margin: 0px;
    }
    h1.lg {
        font-size: 30px;
        line-height: 48px;
    }
    h2 {
        line-height: 30px;
        font-size: 21px;
    }
    h3 {
        font-size: 20px;
        line-height: 30px;
        margin: 0px;
    }
    p {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin: 0px;
    }
    label {
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px;
        color: $neutral-color-n-40;
    }
    p.lg {
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
    }
}

@media (max-width: 574px) {
}

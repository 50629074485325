.profile-dp {
    aspect-ratio: 1;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    max-width: 244px;
    margin: 0 auto;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    input {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        z-index: 2;
        left: 0;
        top: 0;
    }
}
.field-item {
    display: flex;
    gap: 8px;
    align-items: center;
    overflow: hidden;
    width: 100%;
    border: 1px solid $neutral-color-n-80;
    border-radius: 16px;
    padding: 12px 16px;
    .small {
        padding-bottom: 2px;
        color: $neutral-color-n-40;
    }
    .field-value {
        color: $neutral-color-n-10;
    }
}
.number-flag {
    display: flex;
    gap: 8px;
    align-items: center;
}
.call-duration {
    margin-top: 22px;
    padding: 24px;
    display: flex;
    border-radius: 24px;
    background-color: $primary-color-p-50-main;
    color: $white;
    flex-direction: column;
    img {
        max-width: 64px;
        padding-bottom: 10px;
    }
    &.profile-card {
        background-color: transparent;
        border-radius: 0px;
        display: flex;
        padding: 0px;
        flex-direction: row;
        gap: 12px;
        flex-wrap: wrap;
        aspect-ratio: unset;
        & > div {
            flex: 1;
            background-color: $primary-color-p-50-main;
            border-radius: 16px;
            padding: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
            img {
                padding: 0px;
            }
            &.call-radio-btn {
                width: 100%;
                flex: auto !important;
                display: flex;
                align-items: center;
                gap: 8px;
                white-space: unset;
                justify-content: start;
                background-color: $white;
                border: 1px solid $neutral-color-n-80;
                .radio-btn {
                    margin: 0px;
                    label {
                        flex: 1;
                    }
                    input {
                        margin: 0px !important;
                    }
                }
                .form-check-label {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
                    color: $neutral-color-n-10;
                }
            }
        }
        & > div:first-child {
            border: 1px solid $neutral-color-n-80;
            background-color: transparent;
            flex-direction: column;
            justify-content: start;
            align-items: start;
            color: $neutral-color-n-10;
        }
    }
    .mf-card {
        display: flex;
        flex: 1;
        background-color: #0080ff;
        border-radius: 16px;
        padding: 12px;
        align-items: center;
        justify-content: center;
        border: none;
        white-space: nowrap;
        flex-direction: column;
        color: white;
        &.disable-action {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }
}
form {
    flex: 1;
    .radio-btn {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        padding-left: 0px;
        align-items: center;
        .form-check-input {
            width: 52px;
            height: 32px;
            &:checked {
                background-color: $primary-color-p-50-main;
                border-color: $primary-color-p-50-main;
            }
        }
    }
}
.user-interest {
    padding-top: 32px;
    .interest-header {
        top: 80px;
        background-color: rgb(245, 245, 245);
    }
    h3 {
        padding-bottom: 16px;
        color: $primary-color-p-50-main;
    }
}
.interest-wrapper {
    padding: 16px;
    border-radius: 24px;
    border: 1px solid $neutral-color-n-80;
    & > div:not(:last-child) {
        padding-bottom: 32px;
    }
    &.interest-wrapper {
        & > div:not(:last-child) {
            padding-bottom: 24px;
        }
    }
}
.side-profile-col {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 100%;
    flex-direction: column;
}
.user-details-wrapper {
    padding-top: 24px;
}
.full-content {
    display: flex;
    column-gap: 24px;
    row-gap: 16px;
    flex-wrap: wrap;
}
@media (min-width: 993px) and (max-width: 1200px) {
    .duration-container {
        display: flex;
        align-items: start;
        gap: 30px;
        .call-duration {
            aspect-ratio: 1;
            margin: 0px;
        }
        padding-bottom: 20px;
    }
    .call-duration.profile-card {
        aspect-ratio: unset;
    }
    .side-profile-col {
        align-items: flex-start;
        padding-bottom: 20px;
    }
}
.word-counter {
    text-align: end;
    padding-top: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $neutral-color-n-40;
}
.edit-profile-input {
    text-align: center;
    max-width: 244px;
    margin: 0 auto;
    cursor: pointer;
    overflow: hidden;
    button {
        color: $primary-color-p-50-main;
        margin-top: 16px;
        font-size: 22px;
        position: relative;
        input {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
            top: 0;
            left: 0;
            cursor: pointer;
            z-index: 2;
        }
    }
}
.edit-profile-error {
    margin-top: 24px;
    align-items: flex-start;
}
@media (max-width: 992px) {
    .interest-wrapper.interest-wrapper > div:not(:last-child) {
        padding-bottom: 20px;
    }

    .edit-profile-error {
        margin-top: 20px;
    }
}
@media (max-width: 767px) {
    .edit-profile-error {
        margin-top: 16px;
        img {
            max-width: 20px;
            margin-top: 2px;
        }
    }
    .interest-wrapper.interest-wrapper > div:not(:last-child) {
        padding-bottom: 16px;
    }
    .interest-wrapper,
    .call-duration {
        border-radius: 16px;
    }
    .call-duration {
        img {
            max-width: 40px;
        }
    }
}
@media (max-width: 575px) {
    .duration-container {
        padding-bottom: 22px;
    }

    .side-profile-col {
        padding-bottom: 30px;
    }
    .call-duration {
        img {
            max-width: 60px;
        }
    }
}
